$mainColor: #008ad1;
$darkColor: #13082A;
$lightColor: #e2eaff;
$blackColor:#362c49;


$blue:#008AD1;
$black:#0D2139;
$darkblue: #03679B;
$lightblue1: #7398C9;
$lightblue2: #82B0CA;
$lightblue3: #C3E4F9;
$white: #FFFFFF;

$transparentDark: rgba(13, 33, 57, .7);


$serifFont: 'Bodoni', sans-serif;

$mainFont: 'brown-regular', sans-serif;
$mainbFont: 'brown-bold', sans-serif;
$secondFont: 'Bodoni', sans-serif;

@mixin bp-mobile {
	@media only screen and (max-width: 767px) {
		@content;
	}
}

@mixin bp-mobileLarger {
	@media only screen and (min-width: 375px) {
		@content;
	}
}


$breakpoint-tablet: 1024px;
@mixin bp-tablet {
	@media only screen and (min-width: $breakpoint-tablet) {
		@content;
	}
}