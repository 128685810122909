
.icon.icon-performance-log:before {
	content: "\eb8c";			
}

.admin {
	table{
		&.datatbl{
			border: 0;
			
			th {
				cursor:pointer;
				padding-left: 15px;
				white-space: nowrap;
				
				div{
					display: inline-block;
					min-width:10px;
				}
				
				&.sorted{
					div{
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 0 5px 5px 5px;
						border-color: transparent transparent #007bff transparent;
					}
				}
				&.sortedInverse {
					div{
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 5px 5px 0 5px;
						border-color: #007bff transparent transparent transparent;
					}
				}
				
			}
			
			th:first-child,
			td:first-child {
				min-width: 300px;
				max-width: 300px;
				
				white-space: normal;
				word-break: break-word;
			}
			
			&.dbTable{
				.nr{
					max-width: 60px;
					min-width: 60px;															
				}
				th.query{
					max-width: 600px;
					min-width: 600px;					
				}
				td.query{
					max-width: 600px;
					min-width: 600px;										
					text-align:left;
					
				}
				
				th.path, td.path {
			    width: 300px !important;
			    max-width: 300px !important;
			    min-width: 300px !important;
				}				
			}
			
			td {
				position: relative;
				
				.diff {
					position: absolute;
					left: 0;
					bottom: 0;
					
					@include font-size(10px);
					padding: 1px 2px;
					min-width: 30px;
					color: #ffffff;
					@include radius-topright(3px);
				}
				.truncQuery{
					display:none;
				}
				.fullQuery{					
					font-family:monospace;
					max-height:2.8em;
					overflow:hidden;
					@include transition(all 0.3s ease-in-out);
				}								
				.fullQuery.show{
					max-height:45em;
					overflow:auto;
					white-space: pre;
				}
			}
			
			
		}
		
		&.fixedtop {
			thead {
				display: block;
				width: 100%;
			}
			
			tbody {
				display: block;
				width: 100%;
				height: 470px;
				overflow: auto;
			}
			
			td,th:not(:first-child) {
				min-width: 120px;
				max-width: 120px;
			}
		}
	}
	
	
	.performanceLogHeader {
		display: inline-block;
		
		h1 {
			margin:0px;
			padding:0px;
			@include font-size(25px);
			font-weight:bold;
		}
		
		#datepicker {
			width: 142px;
			text-align:center;
			padding: 0;
			margin: 0;
			border: none;
			background: transparent;
			display: inline;
			font-weight:bold;
			font-family: "Lato-Regular", sans-serif;
			cursor: pointer;
			text-decoration: underline;
			@include font-size(25px);
		}
		
		span{
			@include font-size(18px);
			vertical-align: baseline;
		}
	}
	
	.performanceLog {
		.page-block {
			.page-body {
				overflow-x: auto;
			}
		}		
	}
	
	.queryLog{
		.tableDiv{
			margin-top:15px;
		}
		div.logRow{
			padding:5px 0;
			line-height:26px;
		}
		.logRows td{		
			padding:5px;
			line-height:26px;
			&:first-child{
				padding-left:0;			
			}							
			.icon{
				margin:0 5px;
			}
		}		
		
		.explainTable{
			th,td{
				padding:5px;
			}
			i{
				opacity:0.7;				
			}
		}
		
		.explainQuery{
			width:100%;
			height:10em;
			border:1px solid rgba(0,0,0, 0.5);
			overflow:auto;
			font-family:monospace;
		}
		.page-body .jsGroupReport{
			float: right;
    	margin-top: -25px;			
		}
	}
	
	.performanceLogOptions{
		display:inline-block;
		max-width:340px;
		margin-left: 5px;
		.intro{
			margin-bottom:10px;
		}
	}
	.queryLogIntro{
		overflow:hidden;
	}
	.logBlock{
		display:inline-block;
		&.archived{
			float:right;
		}
	}
	
	.table-responsive{
		overflow-x:auto;
	}
}