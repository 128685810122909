$muliFont: muli muli-bold muli-bolditalic muli-extralight muli-extralightitalic muli-italic muli-light muli-lightitalic muli-semi-bolditalic muli-semibold;
@each $muliName in $muliFont {
	$path: 'fonts/Muli/#{$muliName}';
	@font-face {
	    font-family: $muliName;
	    src: url('#{$path}.svg') format('svg');
	    src: url('#{$path}.eot?#iefix') format('embedded-opentype'),
	         url('#{$path}.woff2') format('woff2'),
	         url('#{$path}.woff') format('woff'),
	         url('#{$path}.ttf') format('truetype'),
	         url('#{$path}.svg') format('svg');
	    font-weight: normal;
	    font-style: normal;
	}
}

$fontNames: Lato-Bold Lato-Hairline Lato-Light Lato-Italic Lato-Thin Lato-Regular Lato-Medium;
$fontPath: "fonts/Lato";
@each $fontName in $fontNames {
	$path: '#{$fontPath}/#{$fontName}';
	@font-face {
	    font-family: $fontName;
	    src: url('#{$path}.svg') format('svg');
	    src: url('#{$path}.eot?#iefix') format('embedded-opentype'),
	         url('#{$path}.woff2') format('woff2'),
	         url('#{$path}.woff') format('woff'),
	         url('#{$path}.ttf') format('truetype'),
	         url('#{$path}.svg') format('svg');
	    font-weight: normal;
	    font-style: normal;
	}
}