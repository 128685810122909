body {
	font: 14px "muli", "Lato-Regular", Arial,Helvetica,sans-serif;
}

a {
	&, &:link, &:visited {
		color:#777777;
		text-decoration: none;
	}

	&:hover {
		color: $adminHighlightColor;
	}
}

p {
	&.default {
		margin: 0 0 0.75em 0;
	}

	&.error {
		color: darken($danger, 10);
	}

	&.success {
		color: darken($success, 10);
	}

	&.info {
		color: darken($info, 10);
	}
}

small {
	font-size: 75%;
}

.message,
.errorMessage {
	&.error,
	&.warning,
	&.success {
		display: block;
		padding: 8px 13px;
		margin: 0 0 10px 0;
		color: #fff;
		@include shadow(inset 0 0 1px rgba(255, 255, 255, 0.9));
	}

	&.error {
		border: 1px solid darken($danger, 10);
		background-color: $danger;
	}

	&.warning {
		border: 1px solid darken($warning, 10);
		color: #8a6d3b;
		background-color: $warning;
	}

	&.success {
		border: 1px solid darken($success, 10);
		background-color: $success;
	}
}

.inlineblock {
	display: inline-block;
}

.action {
	margin: 0 auto;
	width: 960px;
}

.dummy {
	display: none !important;
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0;
}

.pull-right {
	float: right;

	@extend %clearfix;
}

.wvBaseContainer {
	min-height: 100vh;
	background: #f9f9f9;

	&.login {
		position: relative;

		.site-info {
			display: block;
			margin: 0 auto 20px;

			.logo {
				display: block;
				background-image: url('../favicon/favicon-192x192.png');
				background-repeat: no-repeat;
				background-position: center center;
				background-size: contain;
				margin: 0 auto;

				width: 35px;
				height: 35px;
			}

			.title {
				display: block;
				@include font-size(20px);
				line-height: 60px;
				text-align: center;
			}
		}
	}

	.content {
		width: 100%;
		margin: 0 auto;

		.login-block {
			border-top: 5px solid $adminMainColor;
			background-color: #fff;
			@include shadow(0px 10px 15px rgba(0,0,0,0.01));

			.inner-block {
				width: 90%;
				display: block;
				margin: 0 auto;
				padding: 15px 0;
			}
		}

		.password-text {
			display: block;
			margin-bottom: 20px;
		}

		hr {
			background: none;
			border-top: 1px solid #f1f1f1;
			border-bottom: 0;
			margin-bottom: 10px;
		}

		input[type=email],
		input[type=text],
		input[type=password] {
			@include radius(3px);
			@include shadow(none);
			@include transition(all 0.3s ease-in-out);

			margin: 0 0 10px 0;
			color: #656565;
			padding: 8px 15px;
			background-color: #ffffff;
			border: 1px solid #d8d5d2;
			display: block;
			width: 100%;

			&.error {
				border-color: $danger;
			}

			&:disabled {
				background-color: #fefafd;
				cursor: not-allowed;
			}

			&:focus {
				border-color: $adminHighlightColor;
				outline: none;
			}

			&:-webkit-autofill {
				-webkit-text-fill-color: #656565 !important;
				background-color: #ffffff !important;
				transition: background-color 500000000s ease-in-out 0s;

				&:hover,
				&:focus,
				&:active {
					background-color: #ffffff !important;
					transition: background-color 500000000s ease-in-out 0s;
				}
			}
		}

		.inputs {
			margin: auto;
		}

		.forgotPass {
			margin: 12px 0;
			@include font-size(14px);
			color: #656565;
			float: right;
		}
	}

	@include bp-small {
		.content {
			width: 100%;
			position: absolute;
			top: 25%;

			.login-block {
				border-top: 0;

				.inner-block {
					width: 30%;
					padding: 50px 0;
				}
			}
		}

		&.register {
			.content {
				top: 20%;
			}
		}
	}
}

.rememberme {
	margin: 15px 0;
	color: #656565;
	font-size: 14px;

	background: #f9f9f9;
	@include radius(30px);

	.toggle {
		cursor: pointer;
		display: block;
	}

	input[type="checkbox"] {
		display: none;

		&:checked + .slide-toggle {
			background-color: #4dde5e;

			&::before {
				@include transform(scale(0));
			}

			&::after {
				@include transform(translate3d(20px,0,0));
			}
		}
	}

	.slide-toggle {
    display: inline-block;
    position: relative;
    flex: none;
    width: 40px;
    height: 20px;
    @include radius(30px);
    background-color: #d7d2cb;
    cursor: pointer;
    @include transition(all 0.1s ease-in-out);
    z-index: 1;
    margin: 10px;

    &::before,
    &::after {
        content: ' ';
        display: block;
        position: absolute;
        top: 1px;
        height: 18px;
        background-color: #fff;

        @include radius(30px);
        @include transform(translate3d(0,0,0));
        @include transition(0.2s cubic-bezier(0, 1.1, 1, 1.1));
    }

    &::before {
        z-index: -1;
        width: 28px;
        right: 1px;
        @include transform(scale(1));
    }

    &::after {
        z-index: 1;
        width: 18px;
        left: 1px;
        @include shadow(0 1px 4px 0.5px rgba(0, 0, 0, 0.25));
    }
	}

	p {
		line-height: 41px;
		display: inline-block;
	}
}

.submitBtn {
	&.login,
	&.register {
		cursor: pointer;
		margin: 7px auto;
		border: 1px solid $adminHighlightColor;
		background-color: #fff;
		border-radius: 3px;
		text-align: center;
		font-size: 18px;

		padding: 10px;
		color: #656565;
	}
}

.registerBtn {
	margin-top: 21px;
	display: block;
}

.registerLinkText {
	display: inline-block;
	font-size: 14px;
	color: #656565;
}

.arrowBtn {
	.register{
		color: #2d61f6;
		display: inline-block;
		font-size: 14px;
		margin-left: 5px;
	}

	.login {
		font-size: 14px;
		margin-top: 32px;
		display: block;
	}
}

.inputOptions {
	border-radius: 3px;
	position: absolute;
	background-color: #fff;
	z-index: 2;
	width: 100%;
	text-align: center;
	border: 1px solid #c1e7ec;
	font-size: 16px;

	.option{
		padding:10px;
		cursor: pointer;
	}
}

.adminHeaderLink {
	position: absolute;
	right: 200px;
	top: 7px;
	z-index: 1;
	white-space: nowrap;
}

.adminMLLink {
	position: absolute;
	right: 340px;
	top: 7px;
	z-index: 1;
	white-space: nowrap;
}

.userName {
	line-height: 65px;
	padding-right: 34px;
}

.headerContainer {
	height: 65px;
	background-color: #fff;
	position: fixed;
	box-shadow: 0 0 5px rgba(0,0,0,0.3);
	-webkit-box-shadow: 0 0 5px rgba(0,0,0,0.3);
	left: 50px;
	right: 0;
	z-index: 1000;

	.logo {
		background: url('img/logo.png') no-repeat center center;
		width: 179px;
		height: 45px;
		background-size: contain;
		display: inline-block;
		margin: 8px 0 0 15px;
	}
}

.headerRight {
	position: absolute;
	right: 0;
	top: 0;
}

.editMLTag {
	display: inline-block;
	width: 16px;
	height: 16px;
	cursor: pointer;
	position: relative;
	z-index: 100;
	opacity: 1;

	img {
		padding: 0 !important;
	}
}

.logBack{
	position: fixed;
  z-index: 1000;
  background: #fff;
  color: #000;
  padding: 10px 20px;
	border-radius: 0 0 30px 30px;
  left: 50%;
  transform: translateX(-50%);
  cursor:pointer;
  box-shadow: 0px 0px 3px #000;
  top:0;
}