// COLORS
$adminHighlightColor: #00b6f0;
$adminMainColor: #00b6f0;
$sidebarBackground: #1f2837;
$adminBackground: #eaeaea;
$breadcrumBackground: #f6f6f6;
$default: #ffffff;
$primary: #00b6f0;
$success: #81CA80;
$info: #6BBCD7;
$warning: #fe8d45;
$danger: #E96562;
$sub: #f1f1f1;

$grayLighter: #f9f9f9;
$grayLight: #999999;
$gray: #666666;
$grayDark: #333333;
$grayDarker: #191919;

/* Btn and text color */
$activeColors: default primary warning danger success info sub graylighter graylight gray graydark graydarker;
$finalActiveColors: (
	default: $default,
	primary: $primary,
	warning: $warning,
	danger: $danger,
	success: $success,
	info: $info,
	sub: $sub,
	graylighter: $grayLighter,
	graylight: $grayLight,
	gray: $gray,
	graydark: $grayDark,
	graydarker: $grayDarker
);

// FONTS
$defaultFont: "Lato-Regular", sans-serif;
$boldFont: "Lato-Bold", sans-serif;
$iconFont: "IcoMoon-Ultimate", sans-serif;

// DON'T EDIT THIS PART BELOW IF YOU DON'T KNOW WHAT YOU ARE DOING

// SIZES
// Header
$mobileHeaderHeight: 100px;
$tabletHeaderHeight: 50px;

// Sidebar
$mobileSide: 220px;
$tabletSide: 160px;
$desktopSide: 220px;
$collapsedSize: 60px;

// TRANSITION
$defaultTransition: all 0.3s ease-in-out;

// GRID
$grid-columns: 12;
$col-space: 10px;

// BREAKPOINTS
$breakpoint-small: 768px;
$breakpoint-medium: 992px;
$breakpoint-large: 1200px;

@mixin bp-small {
	@media only screen and (min-width: $breakpoint-small) {
		@content;
	}
}

@mixin bp-medium {
	@media only screen and (min-width: $breakpoint-medium) {
		@content;
	}
}

@mixin bp-large {
	@media only screen and (min-width: $breakpoint-large) {
		@content;
	}
}

/* iPhone X portrait */
@mixin xmedia {
	@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
		@content;
	}
}

/* iPhone X landscape */
@mixin xmedia {
	@media only screen and (device-width: 812px) and (device-height: 375px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
		@content;
	}
}


// CLEARFIX
%clearfix {
	*zoom: 1;

	&:before,
	&:after {
		content: " ";
		display: table;
	}

	&:after {
		clear: both;
	}
}