html {
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	font-family: sans-serif;
}

body {
	margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
	display: block;
}

audio,
canvas,
progress,
video {
	vertical-align: baseline;
	display: inline-block;
}

audio:not([controls]) {
	height: 0;
	display: none;
}

[hidden],
template {
	display: none;
}

a {
	background-color: rgba(0, 0, 0, 0);
}

a:active,
a:hover {
	outline: 0;
}

abbr[title] {
	border-bottom: 1px dotted;
}

b,
strong {
	font-weight: bold;
}

dfn {
	font-style: italic;
}

h1 {
	margin: .67em 0;
	font-size: 2em;
}

mark {
	color: #000;
	background: #ff0;
}

small {
	font-size: 80%;
}

sub,
sup {
	vertical-align: baseline;
	font-size: 75%;
	line-height: 0;
	position: relative;
}

sup {
	top: -.5em;
}

sub {
	bottom: -.25em;
}

img {
	border: 0;
}

svg:not(:root) {
	overflow: hidden;
}

figure {
	margin: 1em 40px;
}

hr {
	box-sizing: content-box;
	height: 0;
}

pre {
	overflow: auto;
}

code,
kbd,
pre,
samp {
	font-family: monospace;
	font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
	color: inherit;
	font: inherit;
	margin: 0;
}

button {
	overflow: visible;
}

button,
select {
	text-transform: none;
}

button,
html input[type="button"],
input[type="reset"] {
	-webkit-appearance: button;
	cursor: pointer;
}

button[disabled],
html input[disabled] {
	cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

input {
	line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
	box-sizing: border-box;
	padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	height: auto;
}

input[type="search"] {
	-webkit-appearance: none;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

fieldset {
	border: 1px solid silver;
	margin: 0 2px;
	padding: .35em .625em .75em;
}

legend {
	border: 0;
	padding: 0;
}

textarea {
	overflow: auto;
}

optgroup {
	font-weight: bold;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

td,
th {
	padding: 0;
}

[class^="w-icon-"],
[class*=" w-icon-"] {
	speak: none;
	font-variant: normal;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	font-family: webflow-icons, sans-serif !important;
}

.w-icon-slider-right:before {
	content: "";
}

.w-icon-slider-left:before {
	content: "";
}

.w-icon-nav-menu:before {
	content: "";
}

.w-icon-arrow-down:before,
.w-icon-dropdown-toggle:before {
	content: "";
}

.w-icon-file-upload-remove:before {
	content: "";
}

.w-icon-file-upload-icon:before {
	content: "";
}

* {
	box-sizing: border-box;
}

html {
	height: 100%;
	scroll-behavior: smooth;
}

body {
	min-height: 100%;
	color: #333;
	margin: 0;
	font-family: Arial, sans-serif;
	font-size: 14px;
	line-height: 20px;
	background-color: $blue;
}

img {
	max-width: 100%;
	vertical-align: middle;
	display: inline-block;
}

html.w-mod-touch * {
	background-attachment: scroll !important;
}

.w-block {
	display: block;
}

.w-inline-block {
	max-width: 100%;
	display: inline-block;
}

.w-clearfix:before,
.w-clearfix:after {
	content: " ";
	grid-area: 1 / 1 / 2 / 2;
	display: table;
}

.w-clearfix:after {
	clear: both;
}

.w-hidden {
	display: none;
}

.w-button {
	color: $white;
	line-height: inherit;
	cursor: pointer;
	background-color: #3898ec;
	border: 0;
	border-radius: 0;
	padding: 9px 15px;
	text-decoration: none;
	display: inline-block;
}

input.w-button {
	-webkit-appearance: button;
}

html[data-w-dynpage] [data-w-cloak] {
	color: rgba(0, 0, 0, 0) !important;
}

.w-webflow-badge,
.w-webflow-badge * {
	z-index: auto;
	visibility: visible;
	box-sizing: border-box;
	width: auto;
	height: auto;
	max-height: none;
	max-width: none;
	min-height: 0;
	min-width: 0;
	float: none;
	clear: none;
	box-shadow: none;
	opacity: 1;
	direction: ltr;
	font-family: inherit;
	font-weight: inherit;
	color: inherit;
	font-size: inherit;
	line-height: inherit;
	font-style: inherit;
	font-variant: inherit;
	text-align: inherit;
	letter-spacing: inherit;
	-webkit-text-decoration: inherit;
	text-decoration: inherit;
	text-indent: 0;
	text-transform: inherit;
	text-shadow: none;
	vertical-align: baseline;
	cursor: inherit;
	white-space: inherit;
	word-break: normal;
	word-spacing: normal;
	word-wrap: normal;
	background: none;
	border: 0 rgba(0, 0, 0, 0);
	border-radius: 0;
	margin: 0;
	padding: 0;
	list-style-type: disc;
	transition: none;
	display: block;
	position: static;
	top: auto;
	bottom: auto;
	left: auto;
	right: auto;
	overflow: visible;
	transform: none;
}

.w-webflow-badge {
	white-space: nowrap;
	cursor: pointer;
	box-shadow: 0 0 0 1px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .1);
	visibility: visible !important;
	z-index: 2147483647 !important;
	color: #aaadb0 !important;
	opacity: 1 !important;
	width: auto !important;
	height: auto !important;
	background-color: $white !important;
	border-radius: 3px !important;
	margin: 0 !important;
	padding: 6px 8px 6px 6px !important;
	font-size: 12px !important;
	line-height: 14px !important;
	text-decoration: none !important;
	display: inline-block !important;
	position: fixed !important;
	top: auto !important;
	bottom: 12px !important;
	left: auto !important;
	right: 12px !important;
	overflow: visible !important;
	transform: none !important;
}

.w-webflow-badge>img {
	visibility: visible !important;
	opacity: 1 !important;
	vertical-align: middle !important;
	display: inline-block !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: 10px;
	font-weight: bold;
}

h1 {
	margin-top: 20px;
	font-size: 38px;
	line-height: 44px;
}

h2 {
	margin-top: 20px;
	font-size: 32px;
	line-height: 36px;
}

h3 {
	margin-top: 20px;
	font-size: 24px;
	line-height: 30px;
}

h4 {
	margin-top: 10px;
	font-size: 18px;
	line-height: 24px;
}

h5 {
	margin-top: 10px;
	font-size: 14px;
	line-height: 20px;
}

h6 {
	margin-top: 10px;
	font-size: 12px;
	line-height: 18px;
}

p {
	margin-top: 0;
	margin-bottom: 10px;
}

blockquote {
	font-size: 18px;
	line-height: 22px;
}

figure {
	margin: 0 0 10px;
}

figcaption {
	text-align: center;
	margin-top: 5px;
}

ul,
ol {
	margin-top: 0;
	margin-bottom: 10px;
	padding-left: 40px;
}

.w-list-unstyled {
	padding-left: 0;
	list-style: none;
}

.w-embed:before,
.w-embed:after {
	content: " ";
	grid-area: 1 / 1 / 2 / 2;
	display: table;
}

.w-embed:after {
	clear: both;
}

.w-video {
	width: 100%;
	padding: 0;
	position: relative;
}

.w-video iframe,
.w-video object,
.w-video embed {
	width: 100%;
	height: 100%;
	border: none;
	position: absolute;
	top: 0;
	left: 0;
}

fieldset {
	border: 0;
	margin: 0;
	padding: 0;
}

button,
[type="button"],
[type="reset"] {
	cursor: pointer;
	-webkit-appearance: button;
	border: 0;
}

.w-form {
	margin: 0 0 15px;
}

.w-form-done {
	text-align: center;
	background-color: #ddd;
	padding: 20px;
	display: none;
}

.w-form-fail {
	background-color: #ffdede;
	margin-top: 10px;
	padding: 10px;
	display: none;
}

label {
	margin-bottom: 5px;
	font-weight: bold;
	display: block;
}

.w-input,
.w-select {
	width: 100%;
	height: 38px;
	color: #333;
	vertical-align: middle;
	background-color: $white;
	border: 1px solid #ccc;
	margin-bottom: 10px;
	padding: 8px 12px;
	font-size: 14px;
	line-height: 1.42857;
	display: block;
}

.w-input:-moz-placeholder,
.w-select:-moz-placeholder {
	color: #999;
}

.w-input::-moz-placeholder,
.w-select::-moz-placeholder {
	color: #999;
	opacity: 1;
}

.w-input::-webkit-input-placeholder,
.w-select::-webkit-input-placeholder {
	color: #999;
}

.w-input:focus,
.w-select:focus {
	border-color: #3898ec;
	outline: 0;
}

.w-input[disabled],
.w-select[disabled],
.w-input[readonly],
.w-select[readonly],
fieldset[disabled] .w-input,
fieldset[disabled] .w-select {
	cursor: not-allowed;
}

.w-input[disabled]:not(.w-input-disabled),
.w-select[disabled]:not(.w-input-disabled),
.w-input[readonly],
.w-select[readonly],
fieldset[disabled]:not(.w-input-disabled) .w-input,
fieldset[disabled]:not(.w-input-disabled) .w-select {
	background-color: #eee;
}

textarea.w-input,
textarea.w-select {
	height: auto;
}

.w-select {
	background-color: #f3f3f3;
}

.w-select[multiple] {
	height: auto;
}

.w-form-label {
	cursor: pointer;
	margin-bottom: 0;
	font-weight: normal;
	display: inline-block;
}

.w-radio {
	margin-bottom: 5px;
	padding-left: 20px;
	display: block;
}

.w-radio:before,
.w-radio:after {
	content: " ";
	grid-area: 1 / 1 / 2 / 2;
	display: table;
}

.w-radio:after {
	clear: both;
}

.w-radio-input {
	float: left;
	margin: 3px 0 0 -20px;
	line-height: normal;
}

.w-file-upload {
	margin-bottom: 10px;
	display: block;
}

.w-file-upload-input {
	width: .1px;
	height: .1px;
	opacity: 0;
	z-index: -100;
	position: absolute;
	overflow: hidden;
}

.w-file-upload-default,
.w-file-upload-uploading,
.w-file-upload-success {
	color: #333;
	display: inline-block;
}

.w-file-upload-error {
	margin-top: 10px;
	display: block;
}

.w-file-upload-default.w-hidden,
.w-file-upload-uploading.w-hidden,
.w-file-upload-error.w-hidden,
.w-file-upload-success.w-hidden {
	display: none;
}

.w-file-upload-uploading-btn {
	cursor: pointer;
	background-color: #fafafa;
	border: 1px solid #ccc;
	margin: 0;
	padding: 8px 12px;
	font-size: 14px;
	font-weight: normal;
	display: flex;
}

.w-file-upload-file {
	background-color: #fafafa;
	border: 1px solid #ccc;
	flex-grow: 1;
	justify-content: space-between;
	margin: 0;
	padding: 8px 9px 8px 11px;
	display: flex;
}

.w-file-upload-file-name {
	font-size: 14px;
	font-weight: normal;
	display: block;
}

.w-file-remove-link {
	width: auto;
	height: auto;
	cursor: pointer;
	margin-top: 3px;
	margin-left: 10px;
	padding: 3px;
	display: block;
}

.w-icon-file-upload-remove {
	margin: auto;
	font-size: 10px;
}

.w-file-upload-error-msg {
	color: #ea384c;
	padding: 2px 0;
	display: inline-block;
}

.w-file-upload-info {
	padding: 0 12px;
	line-height: 38px;
	display: inline-block;
}

.w-file-upload-label {
	cursor: pointer;
	background-color: #fafafa;
	border: 1px solid #ccc;
	margin: 0;
	padding: 8px 12px;
	font-size: 14px;
	font-weight: normal;
	display: inline-block;
}

.w-icon-file-upload-icon,
.w-icon-file-upload-uploading {
	width: 20px;
	margin-right: 8px;
	display: inline-block;
}

.w-icon-file-upload-uploading {
	height: 20px;
}

.w-container {
	max-width: 940px;
	margin-left: auto;
	margin-right: auto;
}

.w-container:before,
.w-container:after {
	content: " ";
	grid-area: 1 / 1 / 2 / 2;
	display: table;
}

.w-container:after {
	clear: both;
}

.w-container .w-row {
	margin-left: -10px;
	margin-right: -10px;
}

.w-row:before,
.w-row:after {
	content: " ";
	grid-area: 1 / 1 / 2 / 2;
	display: table;
}

.w-row:after {
	clear: both;
}

.w-row .w-row {
	margin-left: 0;
	margin-right: 0;
}

.w-col {
	float: left;
	width: 100%;
	min-height: 1px;
	padding-left: 10px;
	padding-right: 10px;
	position: relative;
}

.w-col .w-col {
	padding-left: 0;
	padding-right: 0;
}

.w-col-1 {
	width: 8.33333%;
}

.w-col-2 {
	width: 16.6667%;
}

.w-col-3 {
	width: 25%;
}

.w-col-4 {
	width: 33.3333%;
}

.w-col-5 {
	width: 41.6667%;
}

.w-col-6 {
	width: 50%;
}

.w-col-7 {
	width: 58.3333%;
}

.w-col-8 {
	width: 66.6667%;
}

.w-col-9 {
	width: 75%;
}

.w-col-10 {
	width: 83.3333%;
}

.w-col-11 {
	width: 91.6667%;
}

.w-col-12 {
	width: 100%;
}

.w-hidden-main {
	display: none !important;
}

@media screen and (max-width: 991px) {
	.w-container {
		max-width: 728px;
	}

	.w-hidden-main {
		display: inherit !important;
	}

	.w-hidden-medium {
		display: none !important;
	}

	.w-col-medium-1 {
		width: 8.33333%;
	}

	.w-col-medium-2 {
		width: 16.6667%;
	}

	.w-col-medium-3 {
		width: 25%;
	}

	.w-col-medium-4 {
		width: 33.3333%;
	}

	.w-col-medium-5 {
		width: 41.6667%;
	}

	.w-col-medium-6 {
		width: 50%;
	}

	.w-col-medium-7 {
		width: 58.3333%;
	}

	.w-col-medium-8 {
		width: 66.6667%;
	}

	.w-col-medium-9 {
		width: 75%;
	}

	.w-col-medium-10 {
		width: 83.3333%;
	}

	.w-col-medium-11 {
		width: 91.6667%;
	}

	.w-col-medium-12 {
		width: 100%;
	}

	.w-col-stack {
		width: 100%;
		left: auto;
		right: auto;
	}
}

@media screen and (max-width: 767px) {

	.w-hidden-main,
	.w-hidden-medium {
		display: inherit !important;
	}

	.w-hidden-small {
		display: none !important;
	}

	.w-row,
	.w-container .w-row {
		margin-left: 0;
		margin-right: 0;
	}

	.w-col {
		width: 100%;
		left: auto;
		right: auto;
	}

	.w-col-small-1 {
		width: 8.33333%;
	}

	.w-col-small-2 {
		width: 16.6667%;
	}

	.w-col-small-3 {
		width: 25%;
	}

	.w-col-small-4 {
		width: 33.3333%;
	}

	.w-col-small-5 {
		width: 41.6667%;
	}

	.w-col-small-6 {
		width: 50%;
	}

	.w-col-small-7 {
		width: 58.3333%;
	}

	.w-col-small-8 {
		width: 66.6667%;
	}

	.w-col-small-9 {
		width: 75%;
	}

	.w-col-small-10 {
		width: 83.3333%;
	}

	.w-col-small-11 {
		width: 91.6667%;
	}

	.w-col-small-12 {
		width: 100%;
	}
}

@media screen and (max-width: 479px) {
	.w-container {
		max-width: none;
	}

	.w-hidden-main,
	.w-hidden-medium,
	.w-hidden-small {
		display: inherit !important;
	}

	.w-hidden-tiny {
		display: none !important;
	}

	.w-col {
		width: 100%;
	}

	.w-col-tiny-1 {
		width: 8.33333%;
	}

	.w-col-tiny-2 {
		width: 16.6667%;
	}

	.w-col-tiny-3 {
		width: 25%;
	}

	.w-col-tiny-4 {
		width: 33.3333%;
	}

	.w-col-tiny-5 {
		width: 41.6667%;
	}

	.w-col-tiny-6 {
		width: 50%;
	}

	.w-col-tiny-7 {
		width: 58.3333%;
	}

	.w-col-tiny-8 {
		width: 66.6667%;
	}

	.w-col-tiny-9 {
		width: 75%;
	}

	.w-col-tiny-10 {
		width: 83.3333%;
	}

	.w-col-tiny-11 {
		width: 91.6667%;
	}

	.w-col-tiny-12 {
		width: 100%;
	}
}

.w-widget {
	position: relative;
}

.w-widget-map {
	width: 100%;
	height: 400px;
}

.w-widget-map label {
	width: auto;
	display: inline;
}

.w-widget-map img {
	max-width: inherit;
}

.w-widget-map .gm-style-iw {
	text-align: center;
}

.w-widget-map .gm-style-iw>button {
	display: none !important;
}

.w-widget-twitter {
	overflow: hidden;
}

.w-widget-twitter-count-shim {
	vertical-align: top;
	width: 28px;
	height: 20px;
	text-align: center;
	background: $white;
	border: 1px solid #758696;
	border-radius: 3px;
	display: inline-block;
	position: relative;
}

.w-widget-twitter-count-shim * {
	pointer-events: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.w-widget-twitter-count-shim .w-widget-twitter-count-inner {
	text-align: center;
	color: #999;
	font-family: serif;
	font-size: 15px;
	line-height: 12px;
	position: relative;
}

.w-widget-twitter-count-shim .w-widget-twitter-count-clear {
	display: block;
	position: relative;
}

.w-widget-twitter-count-shim.w--large {
	width: 36px;
	height: 28px;
}

.w-widget-twitter-count-shim.w--large .w-widget-twitter-count-inner {
	font-size: 18px;
	line-height: 18px;
}

.w-widget-twitter-count-shim:not(.w--vertical) {
	margin-left: 5px;
	margin-right: 8px;
}

.w-widget-twitter-count-shim:not(.w--vertical).w--large {
	margin-left: 6px;
}

.w-widget-twitter-count-shim:not(.w--vertical):before,
.w-widget-twitter-count-shim:not(.w--vertical):after {
	content: " ";
	height: 0;
	width: 0;
	pointer-events: none;
	border: solid rgba(0, 0, 0, 0);
	position: absolute;
	top: 50%;
	left: 0;
}

.w-widget-twitter-count-shim:not(.w--vertical):before {
	border-width: 4px;
	border-color: rgba(117, 134, 150, 0) #5d6c7b rgba(117, 134, 150, 0) rgba(117, 134, 150, 0);
	margin-top: -4px;
	margin-left: -9px;
}

.w-widget-twitter-count-shim:not(.w--vertical).w--large:before {
	border-width: 5px;
	margin-top: -5px;
	margin-left: -10px;
}

.w-widget-twitter-count-shim:not(.w--vertical):after {
	border-width: 4px;
	border-color: rgba(255, 255, 255, 0) #fff rgba(255, 255, 255, 0) rgba(255, 255, 255, 0);
	margin-top: -4px;
	margin-left: -8px;
}

.w-widget-twitter-count-shim:not(.w--vertical).w--large:after {
	border-width: 5px;
	margin-top: -5px;
	margin-left: -9px;
}

.w-widget-twitter-count-shim.w--vertical {
	width: 61px;
	height: 33px;
	margin-bottom: 8px;
}

.w-widget-twitter-count-shim.w--vertical:before,
.w-widget-twitter-count-shim.w--vertical:after {
	content: " ";
	height: 0;
	width: 0;
	pointer-events: none;
	border: solid rgba(0, 0, 0, 0);
	position: absolute;
	top: 100%;
	left: 50%;
}

.w-widget-twitter-count-shim.w--vertical:before {
	border-width: 5px;
	border-color: #5d6c7b rgba(117, 134, 150, 0) rgba(117, 134, 150, 0);
	margin-left: -5px;
}

.w-widget-twitter-count-shim.w--vertical:after {
	border-width: 4px;
	border-color: #fff rgba(255, 255, 255, 0) rgba(255, 255, 255, 0);
	margin-left: -4px;
}

.w-widget-twitter-count-shim.w--vertical .w-widget-twitter-count-inner {
	font-size: 18px;
	line-height: 22px;
}

.w-widget-twitter-count-shim.w--vertical.w--large {
	width: 76px;
}

.w-background-video {
	height: 500px;
	color: $white;
	position: relative;
	overflow: hidden;
}

.w-background-video>video {
	width: 100%;
	height: 100%;
	object-fit: cover;
	z-index: -100;
	background-position: 50%;
	background-size: cover;
	margin: auto;
	position: absolute;
	top: -100%;
	bottom: -100%;
	left: -100%;
	right: -100%;
}

.w-background-video>video::-webkit-media-controls-start-playback-button {
	-webkit-appearance: none;
	display: none !important;
}

.w-background-video--control {
	background-color: rgba(0, 0, 0, 0);
	padding: 0;
	position: absolute;
	bottom: 1em;
	right: 1em;
}

.w-background-video--control>[hidden] {
	display: none !important;
}

.w-slider {
	height: 300px;
	text-align: center;
	clear: both;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	background: #ddd;
	position: relative;
}

.w-slider-mask {
	z-index: 1;
	height: 100%;
	white-space: nowrap;
	display: block;
	position: relative;
	left: 0;
	right: 0;
	overflow: hidden;
}

.w-slide {
	vertical-align: top;
	width: 100%;
	height: 100%;
	white-space: normal;
	text-align: left;
	display: inline-block;
	position: relative;
}

.w-slider-nav {
	z-index: 2;
	height: 40px;
	text-align: center;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	margin: auto;
	padding-top: 10px;
	position: absolute;
	top: auto;
	bottom: 0;
	left: 0;
	right: 0;
}

.w-slider-nav.w-round>div {
	border-radius: 100%;
}

.w-slider-nav.w-num>div {
	width: auto;
	height: auto;
	font-size: inherit;
	line-height: inherit;
	padding: .2em .5em;
}

.w-slider-nav.w-shadow>div {
	box-shadow: 0 0 3px rgba(51, 51, 51, .4);
}

.w-slider-nav-invert {
	color: $white;
}

.w-slider-nav-invert>div {
	background-color: rgba(34, 34, 34, .4);
}

.w-slider-nav-invert>div.w-active {
	background-color: #222;
}

.w-slider-dot {
	width: 1em;
	height: 1em;
	cursor: pointer;
	background-color: rgba(255, 255, 255, .4);
	margin: 0 3px .5em;
	transition: background-color .1s, color .1s;
	display: inline-block;
	position: relative;
}

.w-slider-dot.w-active {
	background-color: $white;
}

.w-slider-dot:focus {
	outline: none;
	box-shadow: 0 0 0 2px $white;
}

.w-slider-dot:focus.w-active {
	box-shadow: none;
}

.w-slider-arrow-left,
.w-slider-arrow-right {
	width: 80px;
	cursor: pointer;
	color: $white;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	margin: auto;
	font-size: 40px;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	overflow: hidden;
}

.w-slider-arrow-left [class^="w-icon-"],
.w-slider-arrow-right [class^="w-icon-"],
.w-slider-arrow-left [class*=" w-icon-"],
.w-slider-arrow-right [class*=" w-icon-"] {
	position: absolute;
}

.w-slider-arrow-left:focus,
.w-slider-arrow-right:focus {
	outline: 0;
}

.w-slider-arrow-left {
	z-index: 3;
	right: auto;
}

.w-slider-arrow-right {
	z-index: 4;
	left: auto;
}

.w-icon-slider-left,
.w-icon-slider-right {
	width: 1em;
	height: 1em;
	margin: auto;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.w-slider-aria-label {
	clip: rect(0 0 0 0);
	height: 1px;
	width: 1px;
	border: 0;
	margin: -1px;
	padding: 0;
	position: absolute;
	overflow: hidden;
}

.w-slider-force-show {
	display: block !important;
}

.w-dropdown {
	text-align: left;
	z-index: 900;
	margin-left: auto;
	margin-right: auto;
	display: inline-block;
	position: relative;
}

.w-dropdown-btn,
.w-dropdown-toggle,
.w-dropdown-link {
	vertical-align: top;
	color: #222;
	text-align: left;
	white-space: nowrap;
	margin-left: auto;
	margin-right: auto;
	padding: 20px;
	text-decoration: none;
	position: relative;
}

.w-dropdown-toggle {
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer;
	padding-right: 40px;
	display: inline-block;
}

.w-dropdown-toggle:focus {
	outline: 0;
}

.w-icon-dropdown-toggle {
	width: 1em;
	height: 1em;
	margin: auto 20px auto auto;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
}

.w-dropdown-list {
	min-width: 100%;
	display: none;
}

.w-dropdown-list.w--open {
	display: block;
}

.w-dropdown-link {
	color: #222;
	padding: 10px 20px;
	display: block;
}

.w-dropdown-link.w--current {
	color: #0082f3;
}

.w-dropdown-link:focus {
	outline: 0;
}

@media screen and (max-width: 767px) {
	.w-nav-brand {
		padding-left: 10px;
	}
}

.w-lightbox-backdrop {
	cursor: auto;
	letter-spacing: normal;
	text-indent: 0;
	text-shadow: none;
	text-transform: none;
	visibility: visible;
	white-space: normal;
	word-break: normal;
	word-spacing: normal;
	word-wrap: normal;
	color: $white;
	text-align: center;
	z-index: 2000;
	opacity: 0;
	-webkit-user-select: none;
	-moz-user-select: none;
	-webkit-tap-highlight-color: transparent;
	background: rgba(0, 0, 0, .9);
	outline: 0;
	font-family: Helvetica Neue, Helvetica, Ubuntu, Segoe UI, Verdana, sans-serif;
	font-size: 17px;
	font-style: normal;
	font-weight: 300;
	line-height: 1.2;
	list-style: disc;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	-webkit-transform: translate(0);
}

.w-lightbox-backdrop,
.w-lightbox-container {
	height: 100%;
	-webkit-overflow-scrolling: touch;
	overflow: auto;
}

.w-lightbox-content {
	height: 100vh;
	position: relative;
	overflow: hidden;
}

.w-lightbox-view {
	width: 100vw;
	height: 100vh;
	opacity: 0;
	position: absolute;
}

.w-lightbox-view:before {
	content: "";
	height: 100vh;
}

.w-lightbox-group,
.w-lightbox-group .w-lightbox-view,
.w-lightbox-group .w-lightbox-view:before {
	height: 86vh;
}

.w-lightbox-frame,
.w-lightbox-view:before {
	vertical-align: middle;
	display: inline-block;
}

.w-lightbox-figure {
	margin: 0;
	position: relative;
}

.w-lightbox-group .w-lightbox-figure {
	cursor: pointer;
}

.w-lightbox-img {
	width: auto;
	height: auto;
	max-width: none;
}

.w-lightbox-image {
	float: none;
	max-width: 100vw;
	max-height: 100vh;
	display: block;
}

.w-lightbox-group .w-lightbox-image {
	max-height: 86vh;
}

.w-lightbox-caption {
	text-align: left;
	text-overflow: ellipsis;
	white-space: nowrap;
	background: rgba(0, 0, 0, .4);
	padding: .5em 1em;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	overflow: hidden;
}

.w-lightbox-embed {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.w-lightbox-control {
	width: 4em;
	cursor: pointer;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 24px;
	transition: all .3s;
	position: absolute;
	top: 0;
}

.w-lightbox-left {
	background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0yMCAwIDI0IDQwIiB3aWR0aD0iMjQiIGhlaWdodD0iNDAiPjxnIHRyYW5zZm9ybT0icm90YXRlKDQ1KSI+PHBhdGggZD0ibTAgMGg1djIzaDIzdjVoLTI4eiIgb3BhY2l0eT0iLjQiLz48cGF0aCBkPSJtMSAxaDN2MjNoMjN2M2gtMjZ6IiBmaWxsPSIjZmZmIi8+PC9nPjwvc3ZnPg==");
	display: none;
	bottom: 0;
	left: 0;
}

.w-lightbox-right {
	background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii00IDAgMjQgNDAiIHdpZHRoPSIyNCIgaGVpZ2h0PSI0MCI+PGcgdHJhbnNmb3JtPSJyb3RhdGUoNDUpIj48cGF0aCBkPSJtMC0waDI4djI4aC01di0yM2gtMjN6IiBvcGFjaXR5PSIuNCIvPjxwYXRoIGQ9Im0xIDFoMjZ2MjZoLTN2LTIzaC0yM3oiIGZpbGw9IiNmZmYiLz48L2c+PC9zdmc+");
	display: none;
	bottom: 0;
	right: 0;
}

.w-lightbox-close {
	height: 2.6em;
	background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii00IDAgMTggMTciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxNyI+PGcgdHJhbnNmb3JtPSJyb3RhdGUoNDUpIj48cGF0aCBkPSJtMCAwaDd2LTdoNXY3aDd2NWgtN3Y3aC01di03aC03eiIgb3BhY2l0eT0iLjQiLz48cGF0aCBkPSJtMSAxaDd2LTdoM3Y3aDd2M2gtN3Y3aC0zdi03aC03eiIgZmlsbD0iI2ZmZiIvPjwvZz48L3N2Zz4=");
	background-size: 18px;
	right: 0;
}

.w-lightbox-strip {
	white-space: nowrap;
	padding: 0 1vh;
	line-height: 0;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	overflow-x: auto;
	overflow-y: hidden;
}

.w-lightbox-item {
	width: 10vh;
	box-sizing: content-box;
	cursor: pointer;
	padding: 2vh 1vh;
	display: inline-block;
	-webkit-transform: translate3d(0, 0, 0);
}

.w-lightbox-active {
	opacity: .3;
}

.w-lightbox-thumbnail {
	height: 10vh;
	background: #222;
	position: relative;
	overflow: hidden;
}

.w-lightbox-thumbnail-image {
	position: absolute;
	top: 0;
	left: 0;
}

.w-lightbox-thumbnail .w-lightbox-tall {
	width: 100%;
	top: 50%;
	transform: translate(0, -50%);
}

.w-lightbox-thumbnail .w-lightbox-wide {
	height: 100%;
	left: 50%;
	transform: translate(-50%);
}

.w-lightbox-spinner {
	box-sizing: border-box;
	width: 40px;
	height: 40px;
	border: 5px solid rgba(0, 0, 0, .4);
	border-radius: 50%;
	margin-top: -20px;
	margin-left: -20px;
	animation: .8s linear infinite spin;
	position: absolute;
	top: 50%;
	left: 50%;
}

.w-lightbox-spinner:after {
	content: "";
	border: 3px solid rgba(0, 0, 0, 0);
	border-bottom-color: $white;
	border-radius: 50%;
	position: absolute;
	top: -4px;
	bottom: -4px;
	left: -4px;
	right: -4px;
}

.w-lightbox-hide {
	display: none;
}

.w-lightbox-noscroll {
	overflow: hidden;
}

@media (min-width: 768px) {
	.w-lightbox-content {
		height: 96vh;
		margin-top: 2vh;
	}

	.w-lightbox-view,
	.w-lightbox-view:before {
		height: 96vh;
	}

	.w-lightbox-group,
	.w-lightbox-group .w-lightbox-view,
	.w-lightbox-group .w-lightbox-view:before {
		height: 84vh;
	}

	.w-lightbox-image {
		max-width: 96vw;
		max-height: 96vh;
	}

	.w-lightbox-group .w-lightbox-image {
		max-width: 82.3vw;
		max-height: 84vh;
	}

	.w-lightbox-left,
	.w-lightbox-right {
		opacity: .5;
		display: block;
	}

	.w-lightbox-close {
		opacity: .8;
	}

	.w-lightbox-control:hover {
		opacity: 1;
	}
}

.w-lightbox-inactive,
.w-lightbox-inactive:hover {
	opacity: 0;
}

.w-richtext:before,
.w-richtext:after {
	content: " ";
	grid-area: 1 / 1 / 2 / 2;
	display: table;
}

.w-richtext:after {
	clear: both;
}

.w-richtext[contenteditable="true"]:before,
.w-richtext[contenteditable="true"]:after {
	white-space: initial;
}

.w-richtext ol,
.w-richtext ul {
	overflow: hidden;
}

.w-richtext .w-richtext-figure-selected.w-richtext-figure-type-video div:after,
.w-richtext .w-richtext-figure-selected[data-rt-type="video"] div:after,
.w-richtext .w-richtext-figure-selected.w-richtext-figure-type-image div,
.w-richtext .w-richtext-figure-selected[data-rt-type="image"] div {
	outline: 2px solid #2895f7;
}

.w-richtext figure.w-richtext-figure-type-video>div:after,
.w-richtext figure[data-rt-type="video"]>div:after {
	content: "";
	display: none;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.w-richtext figure {
	max-width: 60%;
	position: relative;
}

.w-richtext figure>div:before {
	cursor: default !important;
}

.w-richtext figure img {
	width: 100%;
}

.w-richtext figure figcaption.w-richtext-figcaption-placeholder {
	opacity: .6;
}

.w-richtext figure div {
	color: rgba(0, 0, 0, 0);
	font-size: 0;
}

.w-richtext figure.w-richtext-figure-type-image,
.w-richtext figure[data-rt-type="image"] {
	display: table;
}

.w-richtext figure.w-richtext-figure-type-image>div,
.w-richtext figure[data-rt-type="image"]>div {
	display: inline-block;
}

.w-richtext figure.w-richtext-figure-type-image>figcaption,
.w-richtext figure[data-rt-type="image"]>figcaption {
	caption-side: bottom;
	display: table-caption;
}

.w-richtext figure.w-richtext-figure-type-video,
.w-richtext figure[data-rt-type="video"] {
	width: 60%;
	height: 0;
}

.w-richtext figure.w-richtext-figure-type-video iframe,
.w-richtext figure[data-rt-type="video"] iframe {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.w-richtext figure.w-richtext-figure-type-video>div,
.w-richtext figure[data-rt-type="video"]>div {
	width: 100%;
}

.w-richtext figure.w-richtext-align-center {
	clear: both;
	margin-left: auto;
	margin-right: auto;
}

.w-richtext figure.w-richtext-align-center.w-richtext-figure-type-image>div,
.w-richtext figure.w-richtext-align-center[data-rt-type="image"]>div {
	max-width: 100%;
}

.w-richtext figure.w-richtext-align-normal {
	clear: both;
}

.w-richtext figure.w-richtext-align-fullwidth {
	width: 100%;
	max-width: 100%;
	text-align: center;
	clear: both;
	margin-left: auto;
	margin-right: auto;
	display: block;
}

.w-richtext figure.w-richtext-align-fullwidth>div {
	padding-bottom: inherit;
	display: inline-block;
}

.w-richtext figure.w-richtext-align-fullwidth>figcaption {
	display: block;
}

.w-richtext figure.w-richtext-align-floatleft {
	float: left;
	clear: none;
	margin-right: 15px;
}

.w-richtext figure.w-richtext-align-floatright {
	float: right;
	clear: none;
	margin-left: 15px;
}

.w-nav {
	z-index: 1000;
	background: #ddd;
	position: relative;
}

.w-nav:before,
.w-nav:after {
	content: " ";
	grid-area: 1 / 1 / 2 / 2;
	display: table;
}

.w-nav:after {
	clear: both;
}

.w-nav-brand {
	float: left;
	color: #333;
	text-decoration: none;
	position: relative;
}

.w-nav-link {
	vertical-align: top;
	color: #222;
	text-align: left;
	margin-left: auto;
	margin-right: auto;
	padding: 20px;
	text-decoration: none;
	display: inline-block;
	position: relative;
}

.w-nav-link.w--current {
	color: #0082f3;
}

.w-nav-menu {
	float: right;
	position: relative;
}

[data-nav-menu-open] {
	text-align: center;
	min-width: 200px;
	background: #c8c8c8;
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	overflow: visible;
	display: block !important;
}

.w--nav-link-open {
	display: block;
	position: relative;
}

.w-nav-overlay {
	width: 100%;
	display: none;
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	overflow: hidden;
}

.w-nav-overlay [data-nav-menu-open] {
	top: 0;
}

.w-nav[data-animation="over-left"] .w-nav-overlay {
	width: auto;
}

.w-nav[data-animation="over-left"] .w-nav-overlay,
.w-nav[data-animation="over-left"] [data-nav-menu-open] {
	z-index: 1;
	top: 0;
	right: auto;
}

.w-nav[data-animation="over-right"] .w-nav-overlay {
	width: auto;
}

.w-nav[data-animation="over-right"] .w-nav-overlay,
.w-nav[data-animation="over-right"] [data-nav-menu-open] {
	z-index: 1;
	top: 0;
	left: auto;
}

.w-nav-button {
	float: right;
	cursor: pointer;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	padding: 18px;
	font-size: 24px;
	display: none;
	position: relative;
}

.w-nav-button:focus {
	outline: 0;
}

.w-nav-button.w--open {
	color: $white;
	background-color: #c8c8c8;
}

.w-nav[data-collapse="all"] .w-nav-menu {
	display: none;
}

.w-nav[data-collapse="all"] .w-nav-button,
.w--nav-dropdown-open,
.w--nav-dropdown-toggle-open {
	display: block;
}

.w--nav-dropdown-list-open {
	position: static;
}

@media screen and (max-width: 991px) {
	.w-nav[data-collapse="medium"] .w-nav-menu {
		display: none;
	}

	.w-nav[data-collapse="medium"] .w-nav-button {
		display: block;
	}
}

@media screen and (max-width: 767px) {
	.w-nav[data-collapse="small"] .w-nav-menu {
		display: none;
	}

	.w-nav[data-collapse="small"] .w-nav-button {
		display: block;
	}

	.w-nav-brand {
		padding-left: 10px;
	}
}

@media screen and (max-width: 479px) {
	.w-nav[data-collapse="tiny"] .w-nav-menu {
		display: none;
	}

	.w-nav[data-collapse="tiny"] .w-nav-button {
		display: block;
	}
}

.w-tabs {
	position: relative;
}

.w-tabs:before,
.w-tabs:after {
	content: " ";
	grid-area: 1 / 1 / 2 / 2;
	display: table;
}

.w-tabs:after {
	clear: both;
}

.w-tab-menu {
	position: relative;
}

.w-tab-link {
	vertical-align: top;
	text-align: left;
	cursor: pointer;
	color: #222;
	background-color: #ddd;
	padding: 9px 30px;
	text-decoration: none;
	display: inline-block;
	position: relative;
}

.w-tab-link.w--current {
	background-color: #c8c8c8;
}

.w-tab-link:focus {
	outline: 0;
}

.w-tab-content {
	display: block;
	position: relative;
	overflow: hidden;
}

.w-tab-pane {
	display: none;
	position: relative;
}

.w--tab-active {
	display: block;
}

@media screen and (max-width: 479px) {
	.w-tab-link {
		display: block;
	}
}

.w-ix-emptyfix:after {
	content: "";
}

@keyframes spin {
	0% {
		transform: rotate(0);
	}

	100% {
		transform: rotate(360deg);
	}
}

.w-dyn-empty {
	background-color: #ddd;
	padding: 10px;
}

.w-dyn-hide,
.w-dyn-bind-empty,
.w-condition-invisible {
	display: none !important;
}

.wf-layout-layout {
	display: grid;
}

.w-layout-grid {
	grid-row-gap: 4px;
	grid-column-gap: 4px;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr 1fr;
	grid-auto-columns: 1fr;
	display: grid;
}

.w-checkbox {
	margin-bottom: 5px;
	padding-left: 20px;
	display: block;
}

.w-checkbox:before {
	content: " ";
	grid-area: 1 / 1 / 2 / 2;
	display: table;
}

.w-checkbox:after {
	content: " ";
	clear: both;
	grid-area: 1 / 1 / 2 / 2;
	display: table;
}

.w-checkbox-input {
	float: left;
	margin: 4px 0 0 -20px;
	line-height: normal;
}

.w-checkbox-input--inputType-custom {
	width: 12px;
	height: 12px;
	border: 1px solid #ccc;
	border-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
	background-color: #3898ec;
	background-image: url("./img/custom-checkbox-checkmark.svg");
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
	border-color: #3898ec;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
	box-shadow: 0 0 3px 1px #3898ec;
}

.w-pagination-wrapper {
	flex-wrap: wrap;
	justify-content: center;
	display: flex;
}

.w-pagination-previous,
.w-pagination-next {
	color: #333;
	background-color: #fafafa;
	border: 1px solid #ccc;
	border-radius: 2px;
	margin-left: 10px;
	margin-right: 10px;
	padding: 9px 20px;
	font-size: 14px;
	display: block;
}

body {
	color: $black;
	font-family: Ibmplexsans, sans-serif;
	font-size: 16px;
	line-height: 1.2;
}

h1 {
	margin-top: 0;
	margin-bottom: 0;
	font-family: Bodoni, sans-serif;
	font-size: 90px;
	font-weight: 400;
	line-height: 120%;
	letter-spacing: -1.8px;
}

h2 {
	margin-top: 0;
	margin-bottom: 0;
	font-family: Bodoni, sans-serif;
	font-size: 50px;
	font-weight: 400;
	line-height: 55px;
	letter-spacing: -0.02em;
}

h3 {
	margin-top: 0;
	margin-bottom: 0;
	font-family: Bodoni, sans-serif;
	font-size: 36px;
	font-weight: 400;
	line-height: 40px;
	letter-spacing: -0.03em;
}

h4 {
	margin-top: 0;
	margin-bottom: 0;
	font-family: Bodoni, sans-serif;
	font-size: 24px;
	font-weight: 400;
	line-height: 26px;
	letter-spacing: -0.02em;
}

h5{
	font-family: Ibmplexsans, sans-serif;
	font-size: 18px;
	font-weight: 500;
	line-height: 25px;
	letter-spacing: -0.02em;
	text-align: left;
}

h6{
	font-family: Ibmplexsans, sans-serif;
	font-size: 16px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: -0.02em;
	text-align: left;
}

p {
	margin-bottom: 0;
}

.intro, .main-content.generic.contact .content-section .intro p{
	font-family: Ibmplexsans, sans-serif;
	font-size: 22px;
	font-weight: 400;
	line-height: 31px;
	letter-spacing: -0.01em;
	text-align: left;
}

.bodyText{
	font-family: Ibmplexsans, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: -0.02em;
	text-align: left;
}
.labelNoCaps, .button-3.labelNoCaps, a.button-3.labelNoCaps{
	font-family: Ibmplexsans, sans-serif;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.01em;
	text-align: left;
}
.labelYesCaps{
	font-family: Ibmplexsans, sans-serif;
	font-size: 12px;
	font-weight: 500;
	text-transform: uppercase;
	line-height: 14px;
	letter-spacing: 0.02em;
	text-align: left;
}

@media screen and (max-width: 767px) {
	h1 {
		font-size: 46px;
		letter-spacing: -0.03em;
	}
	h2 {
		font-size: 38px;
		line-height: 38px;
	}
	h3 {
		font-size: 30px;
		line-height: 30px;
	}
	h4{
		font-size: 22px;
	}
	h5{
		line-height: 22px;
		letter-spacing: 0em;
	}
	.intro, .main-content.generic.contact .content-section .intro p{
		font-size: 20px;
		line-height: 28px;
	}
	.labelNoCaps, .button-3.labelNoCaps, a.button-3.labelNoCaps{
		letter-spacing: 0em;
	}
	.labelYesCaps{
		font-size: 14px;
		line-height: 17px;
	}
}

.textleftwith4pics .w-layout-grid .gridnode1,
.textrightwithbigpic .w-layout-grid .gridnode2,
.textleftwithbigpic .w-layout-grid .gridnode1,
.detail-content-block .headerblock {
	.intro{
		color:$lightblue3;
	}
}



a {
	text-decoration: underline;
}

.container {
	width: 100%;
	max-width: 120rem;
	margin-left: auto;
	margin-right: auto;
	padding-left: 16px;
	padding-right: 16px;
}

.main-content{
	min-height: calc(100vh - 420px);
}

.main-content .container {
	padding-left: 4rem;
	padding-right: 4rem;
}

.container.is--nav {
	justify-content: space-between;
	align-items: center;
	padding-top: 1rem;
	padding-bottom: 1rem;
	display: flex;
	&.cartvisible{
		.nav-item.cart{
			display:flex;
		}
		.profile-menu-close {
			margin-right: 166px;
		}
		.profileloggedin-menu-close {
			margin-right: 166px;
		}
	}
}

.container.is--relative {
	z-index: 1;
	position: relative;
}

.nav {
	z-index: 80;
	color: $white;
	background-color: rgba(0, 0, 0, 0);
	position: fixed;
	top: 0%;
	bottom: auto;
	left: 0%;
	right: 0%;
	transform: translate3d(0,0,0);
}

.nav-left {
	grid-column-gap: 1rem;
	grid-row-gap: 1rem;
	align-items: center;
	display: flex;
}

.nav-filter {
	grid-column-gap: 2px;
	grid-row-gap: 2px;
	display: flex;
}

.nav-filter-dropdown {
	height: 58px;
	min-width: 12.5rem;
	border-radius: 4px;
	display:none;
	&#filter-step-1, &.active{
		display:block;
	}
}

.nav-filter-dropdown._w--open {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.nav-filter-dropdown.is--disabled {
	opacity: .6;
	cursor: not-allowed;
}

.nav-logo {
	height: 45px;
}

.home-hero-section {
	height: 100svh;
	max-height:100%;
	overflow:hidden;
	color: $white;
	background-color: $black;
	flex-direction: column;
	justify-content: flex-end;
	padding-bottom: 48px;
	display: flex;
	position: relative;

	&.extrapaddingbottom{
		padding-bottom: 4rem;	
	}
	&.noImage{
		background-color: transparent;
		height:auto;
		padding-top:193px;
		padding-bottom: 0;
	}
	&.height60svh{
		height: auto;
		min-height:60svh;
	}
}
	
.main-content .home-hero-section .container{
	padding-left: 0;
	padding-right: 0;
	.home-hero-grid{
		max-width: none;
		h1{
			max-width:70%;
			padding-left:4rem;
		}
		.detail-hero-content {
			width: 80%;
			max-width: 1600px;
			margin-left: auto;
			margin-right: auto;
			padding-left: 0;
			p{
				max-width:70%;
			}
		}
		.home-hero-right{
			max-width:70%;
			padding-left:4rem;
		}
	}
}


.detail-content-block .threecolumnBlocks.links .block.haspicture{
	&:hover{
		.imageContainer {
			border-radius:24px;
		}	
		.stack-card-content-arrow{
			border-radius:24px;
		}
	}
	padding:4px 4px 16px 4px;
	.imageContainer {
		margin-bottom:12px;
		transition: all 0.3s ease;
	}
	> div{
		margin:0;
		h5{padding-left:12px;padding-right:12px;}
		.bodyText{padding-left:12px;padding-right:12px;}
	}

	.stack-card-content-arrow{
		position: absolute;
		top: 10px;
		right: 8px;
		background: white;
		width: 32px;
		height: 32px;
		border-radius: 4px;
		text-align: center;
		padding-top: 6px;
		padding-right: 0px;
		transition: all 0.3s ease;
	}
}

.nav-filter-dropdown-toggle {
	width: 100%;
	-webkit-backdrop-filter: blur(16px);
	backdrop-filter: blur(16px);
	background-color: $transparentDark;
	border-radius: 4px;
	padding: 6px 10px 6px 10px;
}

.nav-filter-dropdown-toggle.is--disabled {
	opacity: .6;
	cursor: not-allowed;
}

.nav-filter-label {
	color: $white;
	text-transform: uppercase;
	font-size: 15px;
	font-weight: 500;
}

.nav-filter-arrow {
	color: $white;
	margin-top: 0;
	margin-bottom: 0;
	margin-right: 0;
	font-size: 16px;
	position: static;
	transition: all 0.3s ease;
}

.nav-filter-dropdown.w-dropdown.opened{
	.nav-filter-arrow {
		transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(180deg) skew(0deg);
	}
}

.nav-filter-text {
	opacity: .5;
	color: $white;
	font-size: 20px;
	font-weight: 500;
	line-height: 28px;
	padding-top: 0px;
}

.nav-filter-dropdown.w-dropdown.opened .nav-filter-text {
	font-size: 15px;
	opacity: 1;
	padding-top: 7px;
	padding-bottom: 6px;
	display:none;
}

.nav-filter-text.is--active {
	opacity: 1;
	font-size: 20px;
}

.nav-filter-meta {
	width: 100%;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 0px;
	display: flex;
}

.home-hero-img {
	width: 100lvw;
	height: 100lvh;
	opacity: 1;
	object-fit: cover;
	position: absolute;
	top: 0%;
	bottom: 0%;
	left: 0%;
	right: 0%;
}

.gradientOverlay{
	background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 35.44%);
}


.home-hero-img.colorOverlay{
	background:#008AD1;
	opacity: 0;
}



.main-content.homepage .gradientOverlay{
	background: rgba(0, 0, 0, .1);
}

.webshop-hero-img{
	width: 100%;
	height: 100%;
	opacity: 1;
	object-fit: cover;
	position: absolute;
	top: 0%;
	bottom: 0%;
	left: 0%;
	right: 0%;
}

.nav-right {
	grid-column-gap: 4px;
	grid-row-gap: 4px;
	align-items: stretch;
	display: flex;
}

.nav-item {
	width: 3.2rem;
	height: 3.2rem;
	-webkit-backdrop-filter: blur(16px);
	backdrop-filter: blur(16px);
	color: $white;
	background-color: $transparentDark;
	border-radius: 4px;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	display: flex;
	&.cart{
		display:none;
	}
}

.nav-item.is--search {
	cursor: pointer;
}

.nav-item-icon {
	width: 1rem;
}

.nav-menu {
	display: none;
}

a.nav-filter-dropdown-link {
	color: rgba(255, 255, 255, 1);
	padding: 5px 0 4px 0;
	font-size:20px;
	font-weight:400;
}

.nav-filter-dropdown.w-dropdown.selected a.nav-filter-dropdown-link {
	color: rgba(255, 255, 255, 0.5);
	&.selected{
		color: rgba(255, 255, 255, 1);
	}
}

.nav-filter-dropdown-link:hover {
	color: $white;
}

a.nav-filter-btn {
	width: 45px;
	height: 45px;
	-webkit-backdrop-filter: blur(16px);
	backdrop-filter: blur(16px);
	color: $white;
	background-color: $transparentDark;
	border-radius: 100vw;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	display: none;
	transition: all 0.3s ease;

	&.active{
		display: flex;
	}
	.label{
		display:none;
	}
	&:hover{
		background-color: rgba(13, 33, 57, .9);
	}
}

.home-hero-grid {
	display: block;
}
.home-hero-section .home-hero-grid{
	max-width:60%;
}

.button-1,
a.button-1 {
	color: $white;
	background-color: rgba(13, 33, 57, .2);
	border: 1px solid rgba(255, 255, 255, .5);
	border-radius: 100vw;
	padding: 14px;
	text-decoration: none;
	transition: all 0.3s ease;
	-webkit-backdrop-filter: blur(16px);
	backdrop-filter: blur(16px);
	cursor:pointer;
	&:hover{
		background: rgba(13, 33, 57, .4);
	}
}

.button-2,
a.button-2 {
	color: $white;
	border: 1px solid rgba(255, 255, 255, .6);
	border-radius: 100vw !important;
	padding: 14px !important;
	text-decoration: none !important;
	font-size: 10px !important;
	font-weight: 500 !important;
	line-height: 14px !important;
	transition: all 0.3s ease;
	cursor:pointer;
	&:hover{
		background-color: rgba(13, 33, 57, .1);
	}
	&.primary{
		background:white;
		color:$black !important;
		border: 1px solid rgba(255, 255, 255, 1);
		&:hover{
			background-color: rgba(255, 255, 255, .9);
		}
	}
}

.button-3,
a.button-3 {
	color: $blue;
	font-family: Ibmplexsans, sans-serif;
	font-size: 10px;
	font-weight: 500;
	line-height: 140%;
	letter-spacing: 0.1px;
	padding:14px 14px 14px 0px;
	transition: all 0.3s ease;
	cursor:pointer;
	&.extraMarginTop{
		margin-top:8px;
	}
}

.whiteSection{
	.button-2, a.button-2{
		border-color: rgba(115, 152, 201, 0.3);
  		color: #008ad1;
		background-color: white;
		font-size: 14px !important;
		line-height: 20px !important;
		&:hover{
			background-color: rgba(13, 33, 57, .1);
		}
	}
} 

.form-group{
	margin-bottom:12px;
}

.btn-text-adjecent{
	margin-top:12px;
}

.login .form-interaction{
	margin-top:12px;
}

.extraSidePadding{
	padding-left:16px;
	padding-right:16px;
}

.button-inner {
	grid-column-gap: 1rem;
	grid-row-gap: 1rem;
	align-items: center;
	display: flex;
}

.section {
	padding-top: 4rem;
	padding-bottom: 4rem;
}

.section.is--blue {
	background-color: $blue;
}

.stack-heading-arrow {
	height: 2rem;
}

.stack-card-img {
	width: 100%;
	height: 100%;
	max-height: 12rem;
	object-fit: cover;
}

.stack-heading-wrap {
	grid-column-gap: 3rem;
	align-items: center;
	display: flex;
}

.stack-component {
	grid-row-gap: 16rem;
	flex-direction: column;
	justify-content: space-between;
	display: flex;
}

.stack-card {
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	color: $white;
	grid-template-rows: auto;
	grid-template-columns: 1fr minmax(0, 1fr);
	grid-auto-columns: 1fr;
	text-decoration: none;
	position: -webkit-sticky;
	position: sticky;
	top: 6rem;
	overflow: hidden;
	border-radius: 0px;
	background-color: $blue;
	transition: all 0.3s ease;
	transform: translate3d(0,0,0);
}


.stack-card.notsticky {
	position: relative;
}

.stack-card.is-2 {
	top: 5.6rem;
}

.stack_card-content {
	height: 100%;
	flex-direction: column;
	justify-content: space-between;
	display: flex;
	position: relative;
}

.text-max-60 {
	max-width: 60ch;
}

.textleftwith4pics .w-layout-grid {
	grid-template-columns: 1fr 1fr 1fr;

	.gridnode1 {
		grid-area: span 2 / span 1 / span 2 / span 1;
		padding: 46px 32px 46px 16px;
		background-color: $darkblue;
	}

	.gridnode2,
	.gridnode3,
	.gridnode4,
	.gridnode5 {
		grid-area: span 1 / span 1 / span 1 / span 1;
	}

}

.textleftwithbigpic .w-layout-grid {
	grid-template-rows: 125px auto 104px;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

	.gridnode1b {
		grid-row-start: 2;
		grid-column-start: 1;
		grid-row-end: 3;
		grid-column-end: 5;
		background: $darkblue;
		padding: 32px 32px 32px 16px;
		display: table;
		border-radius:4px;
		.button-2{
			margin-top:32px;
		}
		.mt-1{
			margin-top: 0;
		}
		.mt-1.hasMaxHeight{
			height: 226px;
			overflow: hidden;
			vertical-align:bottom;
		}
		.row{
			display:table-row;
		}
		.cell{
			display:table-cell;
		}
	}

	.gridnode1 {
		grid-row-start: 1;
		grid-column-start: 1;
		grid-row-end: 2;
		grid-column-end: 13;
		background: $darkblue;
		padding: 36px 32px 36px 16px;
		display: table;

		.row {
			display: table-row;
		}

		.cell {
			display: table-cell;
		}

		.mt-1 {
			vertical-align: bottom;
		}

		p {
			color: $white;
		}

		.date {
			display: block;
			color: $white;
			margin-top: 30px;
		}

		.title {
			display: block;
			color: $white;
			margin: 16px 0px 8px 0px;
			h5{
				margin:0px;
			}
		}

		.stack-card-content-arrow {
			color: $white;
			margin-top: 36px;
			font-size: 18px;
		}
	}

	.gridnode2 {
		grid-row-start: 1;
		grid-column-start: 5;
		grid-row-end: 3;
		grid-column-end: 13;

		.imageContainer {
			width: 100%;
			height: 100%;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
		}

		.mt-1 {
			display: none;
		}
	}

	.gridnode3 {
		grid-row-start: 2;
		grid-column-start: 1;
		grid-row-end: 3;
		grid-column-end: 4;
	}

	.gridnode4 {
		grid-row-start: 2;
		grid-column-start: 4;
		grid-row-end: 3;
		grid-column-end: 7;
	}

	.gridnode5 {
		grid-row-start: 2;
		grid-column-start: 7;
		grid-row-end: 3;
		grid-column-end: 10;
	}

	.gridnode6 {
		grid-row-start: 2;
		grid-column-start: 10;
		grid-row-end: 3;
		grid-column-end: 13;
	}

	.gridnode7 {
		grid-row-start: 3;
		grid-column-start: 1;
		grid-row-end: 4;
		grid-column-end: 13;
		background: $white;
		padding: 32px 32px 32px 16px;
		display: flex;
		align-items: center;
		transition: all 0.3s ease;
		transform: translate3d(0,0,0);
		&:hover{
			border-radius: 24px;
		}
	}
	a.gridnode7{
		color: $blue;
		&.footerblock{
			font-weight:500;
			text-decoration: underline;
		}
	}

	.gridnode3,
	.gridnode4,
	.gridnode5,
	.gridnode6 {
		background: $white;
		border-radius: 4px;
		padding: 4px 4px 16px 4px;
		transition: all 0.3s ease;
		transform: translate3d(0,0,0);
		margin-top:2px;

		.stack-card-bigimg {
			transition: all 0.3s ease;
			transform: translate3d(0,0,0);
		}

		&:hover {
			cursor: pointer;
			border-radius: 24px;

			.stack-card-bigimg {
				border-radius: 20px;
			}

			.stack-card-content-arrow:after {
				content: "→";
			}

		}

		.imageContainer {
			width: 100%;
			height: 180px;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			border-radius: 4px;
		}

		.textsection {
			padding: 0px 10px 0px 10px;
			display: table;
			height: calc(100% - 180px);
		}

		.row {
			display: table-row;
		}

		.cell {
			display: table-cell;
		}

		.row:last-child .cell {
			vertical-align: bottom;
		}

		.date {
			display: block;
			color: $lightblue1;
			margin-top: 16px;
		}

		.title {
			display: block;
			font-size: 16px;
			font-weight: 500;
			line-height: 22px;
			color: $blue;
			margin: 8px 0px 8px 0px;
			h5{
				margin:0px;
			}
		}

		p {
			color: $lightblue1;
		}

		.stack-card-content-arrow {
			color: $blue;
			margin-top: 16px;
			font-size: 18px;
		}
	}
}

.numberedblock {
	.number {
		color: $lightblue1;
	}

	.title {
		color: $blue;
		margin:6px 0px 8px 0px;
		h5{
			margin:0;
		}
	}

	.bodyText {
		color: $lightblue1;
	}
}

.maxHeightWithFade{
	height:80px;
	overflow:hidden;
	position:relative;
	
	&:after{
		content:" ";
		background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0.00) 100%);
		position:absolute;
		bottom:0;
		left:0;
		right:0;
		height:20px;
	}

	&.fh25{
		&:after{
			height:25px;
		}
	}
	&.h125{
		height:125px;
	}
	&.h140{
		height:140px;
	}
	&.h380{
		height:380px;
	}
}

.detail-content-block.opened .maxHeightWithFade{
	height:auto;
	&:after{
		display:none;
	}
}




.textrightwithbigpic .w-layout-grid {
	grid-template-rows: 300px 100px auto;
	grid-template-columns: 1fr 1fr 1fr;

	.gridnode1 {
		grid-row-start: 1;
		grid-column-start: 1;
		grid-row-end: 3;
		grid-column-end: 3;

		.imageContainer {
			width: 100%;
			height: 100%;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
		}
	}

	.gridnode2 {
		grid-row-start: 1;
		grid-column-start: 3;
		grid-row-end: 2;
		grid-column-end: 4;
		background: $darkblue;
		padding: 46px 32px 46px 16px;
		min-height:400px;

		&.noreadmore{
			grid-row-end: 3;
		}

		p {
			color: $lightblue3;
		}
	}

	.gridnode3 {
		grid-row-start: 2;
		grid-column-start: 3;
		grid-row-end: 3;
		grid-column-end: 4;
		background: $darkblue;
		padding: 32px 32px 32px 16px;
	}

	.gridnode4 {
		grid-row-start: 3;
		grid-column-start: 1;
		grid-row-end: 4;
		grid-column-end: 4;
		display: flex;
		flex-wrap: wrap;

		.numberedblock {
			background: $white;
			padding: 46px 16px 46px 16px;
			flex: 1;

			.bodyText {
				overflow: hidden;
			}

		}

	}
}

.textrightwithbigpic .w-layout-grid2 {
	height: 182px;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	display: grid;
	grid-row-gap: 4px;
	grid-column-gap: 0px;
	grid-auto-columns: 1fr;

	.gridnode1 {
		grid-row-start: 1;
		grid-column-start: 1;
		grid-row-end: 2;
		grid-column-end: 2;

	}

	.gridnode2 {
		grid-row-start: 1;
		grid-column-start: 2;
		grid-row-end: 2;
		grid-column-end: 3;
	}

	.gridnode3 {
		grid-row-start: 1;
		grid-column-start: 3;
		grid-row-end: 2;
		grid-column-end: 4;
	}

	.gridnode4 {
		grid-row-start: 1;
		grid-column-start: 4;
		grid-row-end: 2;
		grid-column-end: 5;
	}




}

.mt-1 {
	margin-top: 1rem;
}


.stack-card {
	.stack-card-content-arrow:after {
		content: "↪";
	}
}

a.stack-card-innercard{
	text-decoration: none;
}

.stack-card-innercard {
	color: $darkblue;
	background-color: $white;
	border-radius: 4px;
	padding: 4px;
	transition: all 0.3s ease;
	transform: translate3d(0,0,0);

	.stack-card-img {
		transition: all 0.3s ease;
		transform: translate3d(0,0,0);
	}

	.stack-card-content-arrow:after {
		content: "↪";
	}

	&:hover {
		cursor: pointer;
		border-radius: 24px;
		.stack-card-img {
			border-radius: 20px;
		}
		.stack-card-content-arrow:after {
			content: "→";
		}
	}

	&.dummyitem{
		&:hover {
			cursor: default;
			border-radius: 4px;
		}
	}

	&.locked {
		background: rgba(255, 255, 255, 0.7);
		span.subheaderlabel{
			color:$lightblue1;
			font-weight: 500;
			font-size: 12px;
			line-height: 12px;
			display: block;
			margin-top: 8px;
			span{
				text-decoration: underline;
			}
		}
		.stack-card-content-arrow::after {
			content: " ";
			background-image: url("./img/lockBlue.svg");
			background-position: 50%;
			background-repeat: no-repeat;
			width: 21px;
			height: 21px;
			display: block;
		}
	}

}

.columnspan2{
	grid-column: span 2;
}
.columnspan3{
	grid-column: span 3;
}
.columnspan4{
	grid-column: span 4;
}
.columnspan5{
	grid-column: span 5;
}
.columnspan6{
	grid-column: span 6;
}

.stack-card-content {
	justify-content: space-between;
	align-items: flex-end;
	padding: 16px 10px 12px 10px;
	display: flex;
}

.stack-card-content-arrow {
	font-size: 1.5rem;
	color: $blue;
	line-height: 44px;
}

.detail-hero-content {
	max-width: 90ch;
	grid-column-gap: 1rem;
	grid-row-gap: 1rem;
	flex-direction: column;
	align-items: flex-start;
	padding-left: 7rem;
	display: flex;

	p {
		margin-top: 23px;
	}
}

.nav-menu-component, .profile-menu-component, .profileloggedin-menu-component, .cart-menu-component {
	width: 100%;
	height: 100vh;
	grid-column-gap: 4px;
	grid-row-gap: 4px;
	-webkit-backdrop-filter: blur(16px);
	backdrop-filter: blur(16px);
	background-color: rgba(13, 33, 57, .6);
	flex-direction: column;
	align-items: flex-end;
	padding: 1rem;
	display: none;
	position: fixed;
	top: 0%;
	bottom: 0%;
	left: 0%;
	right: 0%;
	transform: translate3d(0,0,0);
}



.nav-menu-block {
	width: 288px;
	color: $blue;
	background-color: $white;
	border-radius: 4px;
	flex-direction: column;
	align-items: flex-start;
	padding: 8px;
	display: flex;

	a {
		color: $blue;
	}
}

.profile-menu-component, .profileloggedin-menu-component, .cart-menu-component {

	.nav-menu-block{
		background: none;
		padding: 0px;
		border-radius: 0;
	}
	.tabs{
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		.tab{
			flex: 1;
			background: rgba(255, 255, 255, 0.8);
			padding: 16px;
  			border-radius: 4px;
			margin-bottom:4px;
			margin-left:2px;
			margin-right:2px;
			&:first-child{margin-left:0px;}
			&:last-child{margin-right:0px;}
			&.active{
				background: white;
				margin-bottom: -4px;
				border-radius: 4px 4px 0px 0px;
			}
		}
	}
	.menu-content{
		background: white;
		border-radius: 4px;
		padding: 16px;
	}
}

.cart-menu-component {
	.menu-content{
		width:100%;
	}

	.cart-menu-products{
		grid-column-gap: 4px;
		grid-row-gap: 4px;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		max-height: calc(100% - 200px);
  		overflow-y: auto;
	}
}

.main-content.generic.checkout .content-section .detail-tile .table.orders .row .inputwrap{
	position:relative;
}

.myaccount .nawdata .profileblock .input-type-text{
	position:relative;
}

.select.numberSuffixSelectwrapper {	
	position: absolute;
	width: 100%;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0px;
}

.profileloggedin-menu-component{
	.nav-menu-block{
		width:216px;
	}
	.menu-content {
		width:100%;
	}
}

.nav-menu-block.is--secondary {
	grid-column-gap: 4px;
	grid-row-gap: 4px;
	background-color: rgba(0, 0, 0, 0);
	flex-flow: wrap;
	flex: none;
	margin-top: 20px;
	padding: 0;
}

.nav-menu-block.is--cta {
	color: $white;
	background-color: $blue;
	padding-top: 58px;
	font-size: 24px;
	line-height: 34px;
	text-decoration: none;
}

.nav-link {
	color: $blue;
	letter-spacing: -.05rem;
	margin-left: 0;
	margin-right: 0;
	padding: 0;
	font-size: 24px;
	line-height: 34px;
}

.nav-menu-block.multiple .nav-link {
	padding: 4px 0px 4px 0px;
}

.nav-menu-block.multiple .nav-link:last-child {
	padding: 4px 0px 0px 0px;
}

.nav-link.is--secondary {
	white-space: nowrap;
	background-color: $white;
	border-radius: 4px;
	flex: 1;
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
	padding: 8px;
}

.nav-menu-close, .profile-menu-close, .profileloggedin-menu-close, .cart-menu-close {
	width: 3.2rem;
	height: 3.2rem;
	min-height: 3.2rem;
	-webkit-backdrop-filter: blur(16px);
	backdrop-filter: blur(16px);
	color: $black;
	background-color: $white;
	border-radius: 100px;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	display: flex;
}

.main-content.generic .table .row.hidden{
	display:none;
}

.cart-menu-close {
	margin-right: 111px;
}
.profile-menu-close {
	margin-right: 110px;
}
.profileloggedin-menu-close {
	margin-right: 110px;
	img{
		width: 10px;
  		height: 10px;
	}
}

.detail-hero-section {
	height: 100svh;
	max-height:100%;
	overflow:hidden;
	color: $white;
	background-color: $black;
	flex-direction: column;
	justify-content: flex-end;
	padding-bottom: 4rem;
	display: flex;
	position: fixed;
	top: 0%;
	bottom: 0%;
	left: 0%;
	right: 0%;
	transition: all 0.2s ease;
	transform: translate3d(0,0,0);
}

.detail-section-wrap {
	grid-column-gap: 4rem;
	grid-row-gap: 4rem;
	flex-direction: column;
	padding-bottom: 4rem;
	display: flex;
}

.detail-tile {
	grid-column-gap: 22px;
	grid-row-gap: 4rem;
	grid-template-rows: auto;
	grid-template-columns: 1fr 20rem;
	grid-auto-columns: 1fr;
	display: grid;
}

.webshopproduct {
	.detail-tile {
		grid-column-gap: 40px;
	}	
}

.detail-tile.is--blue {
	background-color: $blue;
}

.detail-content {
	width: 80%;
	max-width: 1600px;
	grid-column-gap: 4rem;
	grid-row-gap: 4rem;
	flex-direction: column;
	margin-top: 100svh;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	&.smallmargintop{
		margin-top: 4rem;
	}

	h5{
		color:$blue;
	}
}

.detail-content-block {
	background-color: $white;
	border-radius: 0px;
	padding: 46px 116px 46px 16px;
	grid-column-start: 1;
	grid-column-end: 2;
	position: relative;

	&.nopadding{
		padding:0;
	}
	&.nobackground{
		background:none;
	}

	.footerblock{
		color:$lightblue1;

		&.smallerpadding{
			padding: 32px 16px 32px 16px;
		}

		a.button-2, .button-2{margin:0;}

		.wraptwocolumns{
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}

	.faqTextBlocks{
		.fs_accordion-1_item{
			background:$white;
			border-radius:4px;
			padding: 4px 0px 4px 8px;
			margin-top: 2px;
			transition: all 0.3s ease;

			&:hover{
				border-radius:24px;
			}

			.fs_accordion-1_header {
				padding-left:0px;

				.fs_accordion-1_arrow-wrapper{
					img{
						transition: all 0.3s ease;
					}
				}
				
				.faqquestionwrap{
					white-space:nowrap;
					.number{
						display:inline-block;
						width:20px;
						color:$lightblue1;
						line-height: 21px;
						font-weight: 500;
						text-align:right;
						
					}
					.shop-filter-block-title{
						color:$blue;
						text-transform: none;
						display:inline-block;
						margin-left:10px;
						white-space: wrap;
						
						h5{
							margin-top:0px;
							margin-bottom:0px;
						}
						span{
							display:inline-block;
							width:20px;
							color:$lightblue1;
							font-size:10px;
							line-height:14px;
							font-weight: 500;
							text-align:right;
							margin-right:10px;
						}
					}
				}

				
			}
			.fs_accordion-1_content{
				color:$lightblue1;
				padding: 0px 24px 0px 30px;
  				margin-bottom: 8px;
				margin-top: -8px;
				display:none;
				height:0px;
				/*transition: all 0.3s ease;*/
				transform: translate3d(0,0,0);
			}
			
		}
	}

	.headerblockTwocolumns{
		display: grid;
		grid-auto-columns: minmax(0, 1fr);
		grid-auto-flow: column;
		grid-column-gap: 4px;
		grid-row-gap: 4px;

		.headerblock{
			margin:0;
		}
		
		.detailpagebigrightimage{
			width:100%;
			height:311px;
		}
	}

	.threecolumnBlocks{
		margin-top:2px;
		grid-column-gap: 4px;
		grid-row-gap: 4px;
		display: grid;
  		grid-template-columns: repeat(3, minmax(0, 1fr));

		.block{
			background:$white;
			padding:16px;
			border-radius:4px;
			justify-content: space-between;
			align-items: flex-start;
			display: flex;
			transition: all 0.3s ease;
			transform: translate3d(0,0,0);
			overflow-x: hidden;
			&>div{
				width: 100%;
  				overflow: hidden;
				margin-right: 8px;
				word-break: keep-all;
   				word-wrap: break-word;
			}

			h5{
				color: $blue;
				padding-right:16px;
				margin-top:0px;
				margin-bottom:0px;
			}
			.subheaderlabel{
				color:$lightblue1;
				font-weight: 500;
				font-size: 12px;
				line-height: 12px;
				display: block;
  				margin-top: 8px;
				span{
					text-decoration: underline;
				}
			}
			.stack-card-content-arrow{
				line-height:20px;
			}
			.stack-card-content-arrow:after {
				content: "↪";
			}
			.stack-card-content-arrow.upright:after {
				content: "→";
				transform: rotate(-45deg);
				display: block;
				font-size: 14px;
			}
			

			&.locked{
				background:rgba(255, 255, 255, 0.7);
				.stack-card-content-arrow:after {
					content: " ";
					background-image: url("./img/lockBlue.svg");
					background-position: 50%;
					background-repeat: no-repeat;
					width: 21px;
					height: 21px;
					display: block;
				}
				&:hover {
					.stack-card-content-arrow:after {
						content: " ";
					}
				}
			}

			&:hover {
				cursor: pointer;
				border-radius: 24px;
		
				.stack-card-content-arrow:after {
					content: "→";
				}
		
			}
		}
	}

	.detailpagebigimagewrapper{
		height:554px;
	}
	.detailpagenormalimagewrapper{
		height:auto;
	}

	.detailpagebigimage{
		height:554px;
		width: 120%;
  		position: absolute;
		margin: 16px 0px 16px -16px;
		background-repeat: no-repeat;
  		background-size: contain;
		@media screen and (min-width: 1200px) {
			width: 115%;
		}
		@media screen and (min-width: 1400px) {
			width: 110%;
		}
	}

	.detailpagenormalimage{
		height:auto;
		width: 100%;
  		position: relative;
		margin: 16px 0px 16px 0px;
		background-repeat: no-repeat;
  		background-size: contain;
		@media screen and (min-width: 1200px) {
			width: 100%;
		}
		@media screen and (min-width: 1400px) {
			width: 100%;
		}
	}

	.detailpagesmallimage{
		width:325px;
		height:420px;
		background-repeat: no-repeat;
  		background-size: contain;
		position: absolute;
		right: -225px;
	}

	.headerblock{
		background:$darkblue;
		padding: 46px 16px 46px 16px;
		h3{
			color:$white;
		}
		p{
			color:$lightblue3;
			margin-top: 5px;
			max-width:530px;
		}
		margin-bottom:2px;
	}

	.footerblock{
		background:$white;
		padding: 46px 16px 46px 16px;
		margin-top:2px;
		a{
			color:$blue;
			font-weight:500;
			text-decoration: underline;
		}
	}
	a.footerblock{
		display: block;
		transition: all 0.3s ease;
		transform: translate3d(0,0,0);
		color:$blue;
		font-weight:500;
		text-decoration: underline;
		&:hover{
			border-radius: 24px;
		}
	}

	.photoTextBlocks{
		grid-column-gap: 2px;
		grid-row-gap: 2px;
		flex-wrap: wrap;
		align-items: stretch;
		display: flex;

		.stack-card-innercard {
			flex: 1 0 45%;
		}

		&.threecols, &.fourcols{
			.stack-card-innercard {
				flex: 1 0 31%;

				.textsection {
					padding: 0px 10px 0px 10px;
					display: table;
					height: 230px;
				}
		
				.row {
					display: table-row;
				}
		
				.cell {
					display: table-cell;
				}
		
				.row:last-child .cell {
					vertical-align: bottom;
				}
		
				.date {
					display: block;
					color: $lightblue1;
					margin-top: 16px;
				}
				
				.title {
					display: block;
					color: $blue;
					margin: 8px 0px 8px 0px;
					h5{
						margin-top:0px;
						margin-bottom:0px;
					}
				}
		
				p {
					color: $lightblue1;
				}
		
				.stack-card-content-arrow {
					color: $blue;
					margin-top: 16px;
					font-size: 18px;
				}

			}
		}

		&.fourcols{
			grid-template-columns: repeat(4, minmax(0, 1fr));
  			display: grid;

			.imageContainer.stack-card-img{
				min-height:180px;
				height: 180px;
			}
		}

	}

	.webshopitems{
		grid-column-gap: 2px;
		grid-row-gap: 2px;
		flex-wrap: wrap;
		align-items: stretch;
		display: flex;

		.shop-product-list-item {
			flex: 1 0 31%;
			.shop-product-card{
				width:auto;
				height:100%;
			}
		}
	}

	p.text {
		font-size: 14px;
		line-height: 20px;
		font-weight: 400;
	}

	.button-2,
	a.button-2 {
		border-color: rgba(115, 152, 201, 0.3);
		color: $blue;
		margin-top: 30px;
	}

	.mt-1 {
		margin-top: 24px;
	}
}

.h3 {
	font-size: 2.5rem;
}

.text-lightblue {
	color: #b2caea;
}
.text-lightblue2{
	color: $lightblue2;
}

.text-darkblue {
	color: $blue;
}

.text-intro {
	font-size: 1.2rem;
	line-height: 1.4;
}

.text-intro.text-midblue {
	color: $lightblue1;
}

.mt-2 {
	margin-top: 2rem;
}

.text-midblue {
	color: $lightblue1;
}

.detail-sidebar {
	grid-column-gap: 4px;
	grid-row-gap: 4px;
	flex-direction: column;
	position: relative;
	grid-column-start: 2;
	grid-column-end: 3;
	grid-row-start: 1;
	grid-row-end: 5;
}

.detail-sidebar-item {
	grid-column-gap: 1rem;
	grid-row-gap: 1rem;
	-webkit-backdrop-filter: blur(16px);
	backdrop-filter: blur(16px);
	color: $white;
	background-color: $transparentDark;
	border-radius: 4px;
	flex-direction: column;
	padding: 1rem;
	text-decoration: none;
	display: flex;
	&.notransparency{
		background-color: $darkblue;
	}
}

.nav-search {
	height: 3.2rem;
	border-radius: 4px;
	align-items: center;
	margin-bottom: 0;
	/*display: flex;*/
	position: relative;
	display:none;
}

.utility-page-wrap {
	width: 100vw;
	height: 100vh;
	max-height: 100%;
	max-width: 100%;
	justify-content: center;
	align-items: center;
	display: flex;
}

.utility-page-content {
	width: 260px;
	text-align: center;
	flex-direction: column;
	display: flex;
}

.utility-page-form {
	flex-direction: column;
	align-items: stretch;
	display: flex;
}

.nav-search-input {
	height: 100%;
	color: $white;
	background-color: transparent;
	border: 1px #000;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	margin-bottom: 0;
	padding: 0 1rem;
	font-size: 1rem;
}

.nav-search-input::-ms-input-placeholder {
	color: rgba(255, 255, 255, .6);
}

.nav-search-input::placeholder {
	color: rgba(255, 255, 255, .6);
}


.nav-item.search {
	width: 3.2rem;
	height: 3.2rem;
	color: $white;
	background-color: $transparentDark;
	-webkit-backdrop-filter: blur(16px);
	backdrop-filter: blur(16px);
	background-position: right 16px center;
	background-repeat: no-repeat;
	background-size: auto 30%;
	border-radius: 4px;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	display: flex;
	cursor:pointer;
	background-image: url("./img/searchicon.png");
	&.opened{
		width: auto;
		.nav-search.w-form{
			display: flex;
			opacity: 1;
			width: 100%;
			height: 51.2px;
		}
	}
}



.nav-item-search.is--search {
	cursor: pointer;
}

.closeMobileFilter{
	float:right;
}

.nav.w-nav.activeMobileFilter{bottom: 0;}
.nav-overlayblur{display:none;}

.nav.w-nav.activeMobileFilter .container.is--nav{display:block;}
.nav.w-nav.activeMobileFilter .nav-left{display:block;}

.nav.w-nav.activeMobileFilter .nav-filter-dropdown{
  width: calc(100% - 47px);
  margin-left: 0;
}

.nav.w-nav.activeMobileFilter .closeMobileFilter a{
	display:block;
	background-image: url("./img/closeiconWhite.svg");
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 12px;
	background-color:rgba(13, 33, 57, 0.4);
	backdrop-filter: blur(16px);
	cursor:pointer;
	width: 45px;
	height: 45px;
	position: relative;
	border-radius: 100px;
}

.nav.w-nav.activeMobileFilter .w-nav-brand{display:none;}
.nav.w-nav.activeMobileFilter .nav-right{display:none;}
.nav.w-nav.activeMobileFilter .nav-filter{display:block;}
.nav.w-nav.activeMobileFilter .nav-filter-dropdown {margin-bottom:2px; height: auto;}
.nav.w-nav.activeMobileFilter .nav-overlayblur{
	position:fixed;
	left:0;
	right:0;
	top:0;
	bottom:0;
	display: block; 
	opacity: 1;
	width: 100%; 
	height: 100vh;
	backdrop-filter: blur(16px);
	background-color: rgba(13, 33, 57, .3);  
	transform: translate3d(0, 0, 0);
}

.nav.w-nav.activeMobileFilter a.nav-filter-btn {
	position: fixed;
	bottom: 16px;
	left: 16px;
	right: 16px;
	width: auto;
	background: white;
	color: $blue;
  	font-weight: 500;
  
	.label {
		display:block;
		flex: 1;
		margin-left: 16px;
		font-size: 10px;
	}
	.arrow {
		margin-right: 18px;
		font-size: 12px;
	}
}

.nav-item.filter{
	cursor: pointer;
	display:none;
}

@media screen and (max-width: 767px) {
	.main-content{
		min-height: unset;
	}
	.nav-item.filter{
		display:flex;
	}
}

.nav-item-menu {
	width: 3.2rem;
	height: 3.2rem;
	-webkit-backdrop-filter: blur(16px);
	backdrop-filter: blur(16px);
	color: $white;
	background-color: $transparentDark;
	border-radius: 4px;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	display: flex;
	&.hamburger, &.profile {
		color: white;
		font-weight: 500;
		.nav-item-icon{
			margin-left:10px;
		}
	}
	&.hamburger {
		width: 107px;
	}
	&.profile {
		width: 99px;
	}
}

.nav-item-menu.is--search {
	cursor: pointer;
}

.nav-search-wrap {
	position: relative;
	padding-right:50px;
	width:50px;
}

.nav-search-extra {
	-webkit-backdrop-filter: blur(16px);
	backdrop-filter: blur(16px);
	background-color: rgba(13, 33, 57, .5);
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
	padding: 1rem;
	display: none;
	position: absolute;
	top: 3.2rem;
	bottom: auto;
	left: 0%;
	right: 0%;
}

.search-link {
	color: $white;
	background-color: rgba(255, 255, 255, .1);
	margin-left: 0;
	margin-right: 0;
	padding: .4rem;
	line-height: 1;
}

.search-link.is--secondary {
	background-color: rgba(13, 33, 57, .1);
	border-radius: 4px;
	padding-left: .4rem;
	padding-right: .4rem;
	font-size: 1.2rem;
}

.search-related {
	grid-column-gap: 2px;
	grid-row-gap: 2px;
	flex-wrap: wrap;
	margin-top: 1rem;
	display: flex;
}

.nav-filter-form {
	display: none;
}

.layered-content {
	z-index: 999;
	width: 100%;
	height: 100svh;
	justify-content: flex-end;
	padding: 1rem;
	display: none;
	position: fixed;
	top: 0%;
	bottom: 0%;
	left: 0%;
	right: 0%;
	transform: translate3d(0,0,0);
}

.layered-content-inner {
	width: 100%;
	max-width: 50vw;
	background-color: $white;
	border-radius: 1rem;
	position: relative;
	overflow-y: auto;
}

.layered-close {
	width: 45px;
	height: 45px;
	background-color: $white;
	background-image: url("./img/closeicon.png");
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: auto 20%;
	border: 1px solid $darkblue;
	border-radius: 100vw;
	position: fixed;
	top: 31px;
	bottom: auto;
	left: auto;
	right: 31px;
	transform: translate3d(0,0,0);
}

.layered-content-bg {
	width: 100%;
	height: 100%;
	-webkit-backdrop-filter: blur(3px);
	backdrop-filter: blur(3px);
	background-color: rgba(13, 33, 57, .8);
	position: absolute;
	top: 0%;
	bottom: 0%;
	left: 0%;
	right: 0%;
}

.nav-search-static-text {
	opacity: .4;
	white-space: nowrap;
	position: absolute;
	top: 1rem;
	bottom: 0%;
	left: auto;
	right: 0px;
}

.webshop-grid {
	grid-column-gap: 22px;
	grid-row-gap: 4px;
	grid-template-rows: auto;
	grid-template-columns: auto 1fr;
	align-items: start;
	position: relative;
}

.shop-products-wrap {
	grid-column-gap: 2px;
	grid-row-gap: 2px;
	align-items: stretch;
	grid-template-columns: repeat(5, minmax(0, 1fr));
  	display: grid;
}

@media screen and (max-width: 1690px) {
	.shop-products-wrap {
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}
}

@media screen and (max-width: 1460px) {
	.shop-products-wrap {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
}

@media screen and (max-width: 1230px) {
	.shop-products-wrap {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
}

@media screen and (max-width: 990px) {
	.shop-products-wrap {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}
}

@media screen and (max-width: 767px) {
	.shop-products-wrap {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
}

.shop-hero-section {
	min-height: 60svh;
	color: $white;
	background-color: $black;
	flex-direction: column;
	justify-content: flex-end;
	padding-bottom: 2rem;
	display: flex;
	position: relative;
}

.shop-product-card {
	height: 100%;
	grid-column-gap: 1rem;
	grid-row-gap: 1rem;
	color: $blue;
	background-color: $white;
	border-radius: 4px;
	flex-direction: column;
	align-items: center;
	padding: .25rem .25rem 1rem;
	text-decoration: none;
	display: flex;
	/*width: 267px;*/
	transition: all 0.3s ease;
	transform: translate3d(0,0,0);

	.shop-product-img{
		height: 192px;
		width: 100%;
		max-width: 192px;
		background-repeat: no-repeat;
  		background-size: contain;
		background-position: bottom;
		margin-top: 12px;
		margin-bottom: 0px;
		margin-left:6px;
		margin-right:6px;
		&.noimage{
			height:unset;
		}
	}
  	
	.shop-product-card-name{
		color:$blue;
		margin-top: 0px;
		margin-bottom: 0px;
	}
	.shop-product-card-vakgebied{
		color:$lightblue1;
		opacity: 0.5;
		margin-top: 0px;
	}
	.shop-product-card-price{
		color:$lightblue1;
	}
	.shop-product-card-availability{
		color:$lightblue1;
		font-size:10px;
		line-height:14px;
		font-weight:500;
	}
	.shop-product-card-warning{
		color:$lightblue1;
		font-size:10px;
		line-height:14px;
		font-weight:500;
	}
	.shop-product-card-intro{
		color:$lightblue1;
		font-size:14px;
		line-height:20px;
		font-weight:400;
	}
	.button-shop{
		color:white;
		font-size:12px;
		line-height:14px;
		font-weight:500;
		border:1px solid $darkblue;
		padding: 0.8rem 0.8rem 0.8rem 0.8rem;
		background:$darkblue;
	}
	&:hover{
		border-radius:24px;
		.button-shop{
			opacity: 1;
		}
	}
}

.presentExemplaarBlocksWrapper .shop-product-card h5.shop-product-card-vakgebied {
	font-size:12px;
}


.shop-product-list-item.dummyproduct{
	.shop-product-card {
		&:hover{
			border-radius:4px;
		}
	}
}

.shop-product-img {
	height: 6.5rem;
}

.shop-product-content {
	width: 100%;
	flex: 1;
	grid-column-gap: 1rem;
	grid-row-gap: 1rem;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	padding-left: 10px;
	padding-right: 10px;
	display: flex;
	&.nopic{
		padding-top:8px;
	}
}

.button-shop {
	opacity: 0;
	color: $blue;
	background-color: rgba(0, 0, 0, 0);
	border: 1px solid #b2caea;
	border-radius: 100vw;
	padding: .8rem 1rem;
	text-decoration: none;
	transition: all 0.3s ease;
	transform: translate3d(0,0,0);
}

.shop-filter-sidebar {
	min-width: 25rem;
	flex-direction: column;
	position: -webkit-sticky;
	position: sticky;
	top: 1rem;
}

.shop-filter-block {
	color: $white;
	background-color: rgba(3, 103, 155, 0.6);
	border-radius: 0px;
	padding: 1rem;
	font-size:10px;
	line-height:12px;
	margin-bottom:6px;
	a{
		color: $white;
		text-decoration: underline;
		font-size:12px;
	}
}

.shop-filter-block {
	&:first-child, &.lastitem{
		background-color: $darkblue;
	}
}


.shop-filter-block.is--filters {
	flex-direction: column;
	grid-template-rows: auto auto;
	grid-template-columns: 8rem 1fr;
	grid-auto-columns: 1fr;
	padding: 0;
	display: flex;
	margin-bottom:6px;
}

.shop-filter-block.is--filters.is--hidden {
	display: none;
}

.shop-filter-form {
	grid-column-gap: .5rem;
	grid-row-gap: .5rem;
	flex-direction: column;
	display: flex;
}

.shop-filter-search {
	height: 3rem;
	color: $white;
	background-color: rgba(13, 33, 57, .4);
	background-image: url("./img/searchicon.png");
	background-position: 95%;
	background-repeat: no-repeat;
	background-size: .8rem;
	border: 1px #000;
	border-radius: 4px;
	margin-top: 1rem;
	margin-bottom: 0;
	padding: 1rem 3.5rem 1rem 1rem;
	font-size: 1rem;
	font-weight: 400;
}

.shop-filter-search:focus {
	border-color: #501aff;
}

.shop-filter-search::-ms-input-placeholder {
	color: rgba(178, 202, 234, .4);
}

.shop-filter-search::placeholder {
	color: rgba(178, 202, 234, .4);
}

.shop-filter-block-title {
	text-transform: uppercase;
}

.shop-filter-block-header {
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	display: flex;
}

.shop-filter-block-content {
	grid-column-gap: 4px;
	grid-row-gap: 4px;
	flex-direction: column;
	padding-bottom: .5rem;
	padding-left: .5rem;
	padding-right: .5rem;
	display: flex;

	.sublabels{
		margin-left:30px;
		margin-bottom:5px;
		display:none;
		&.active{
			display:block;
		}
	}
}

.loading-overlay {
	opacity: 0;
	visibility: hidden;
	transition: all 0.4s linear;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 1000;
	background: rgba(0, 0, 0, 0.5);
	.imgwrapper {
		img{
			width: 200px;	
		}
		width: 200px;
		margin: 0 auto;
		display: block;
	}
	&.active {
		opacity: 1;
		visibility: visible;
	}
	span {
		position: absolute;
		top: calc(50% - 100px);
		left: 0;
		margin: 0 auto;
		display: inline-block;
		right: 0;
		text-align: center;
		font-size: 18px;
		color: #fff;
	}
}


.text-color-green {
	color: #3c7c43;
}

.fs-checkbox_label {
	margin-bottom: 1rem;
}

.fs-checkbox_row {
	min-height: 3rem;
	grid-column-gap: 1rem;
	grid-row-gap: 1rem;
	grid-template-rows: auto;
	grid-template-columns: auto;
	grid-auto-columns: auto;
	grid-auto-flow: column;
	align-items: start;
	display: grid;
}

.fs-checkbox_field-1 {
	cursor: pointer;
	border-radius: 2px;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 0;
	padding: 8px 8px 8px 8px;
	display: flex;
	position: relative;
}

.fs-checkbox_field-1:hover {
	opacity: .7;
}

.fs-checkbox_field-1.is-active {
	color: $blue;
	background-color: $white;
	background-image: url("./img/closeicon.png");
	background-position: 96% 51%;
	background-repeat: no-repeat;
	background-size: .6rem;
}

.fs-checkbox_icon-1 {
	width: 1.5rem;
	height: 1.5rem;
	margin-right: .5rem;
}

.fs-checkbox_button-1 {
	width: 100%;
	height: 100%;
	opacity: 1;
	border-style: none;
	border-radius: 0;
	margin-top: 0;
	margin-left: 0;
	display: block;
	position: absolute;
	top: 0%;
	bottom: auto;
	left: 0%;
	right: 0%;
}

.fs-checkbox_button-1.w--redirected-checked {
	background-color: rgba(0, 0, 0, 0);
	background-image: none;
}

.fs-checkbox_button-1.w--redirected-focus {
	box-shadow: none;
}

.fs-checkbox_button-1.w--redirected-focus-visible {
	box-shadow: none;
	outline-offset: 4px;
	outline: 2px solid #9b9b9b;
}

.fs-checkbox_label-1 {
	z-index: 1;
	margin-bottom: 0;
	position: relative;
}

.fs-checkbox-icon {
	width: .7rem;
	position: absolute;
	right: 1rem;
}

.shop-filter-switch-row {
	justify-content: space-between;
	align-items: center;
	display: flex;
}

.fs-checkbox_toggle-7 {
	width: 2rem;
	cursor: pointer;
	border: 1px solid rgba(178, 202, 234, .6);
	border-radius: 999rem;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 0;
	padding: 1px;
	display: flex;
	position: relative;
}

.fs-checkbox_toggle-dot-7 {
	z-index: 2;
	width: .9rem;
	height: .9rem;
	background-color: $white;
	border-radius: 999rem;
	flex: none;
	display: block;
	position: relative;
	transition: all 0.05s ease;
}

label.checkboxslider{
	.fs-checkbox_mask-7{
		background-color: rgb(3, 103, 155);
	}
}

label.checkboxslider.checked {
	.fs-checkbox_toggle-dot-7{
		transition: all 0.3s ease;
	}
	.fs-checkbox_toggle-dot-7{
		transform:translate3d(0.85rem, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
	}
	.fs-checkbox_mask-7{
		background-color:rgb(115, 152, 201);
	}
}

.fs-checkbox_button-7 {
	width: 100%;
	height: 100%;
	border-radius: 999rem;
	margin-top: 0;
	margin-left: 0;
	display: block;
	position: absolute;
	top: 0%;
	bottom: 0%;
	left: 0%;
	right: 0%;
}

.fs-checkbox_button-7:focus-visible {
	outline-offset: 4px;
	outline: 2px solid #9b9b9b;
}

.fs-checkbox_button-7[data-wf-focus-visible] {
	outline-offset: 4px;
	outline: 2px solid #9b9b9b;
}

.fs-checkbox_label-7 {
	width: auto;
	margin-bottom: 0;
	font-weight: 500;
	display: none;
	position: absolute;
	top: 0%;
	bottom: auto;
	left: 0%;
	right: auto;
}

.fs-checkbox_mask-7 {
	z-index: 1;
	background-color: $darkblue;
	border-radius: 999rem;
	position: absolute;
	top: 0%;
	bottom: 0%;
	left: 0%;
	right: 0%;
	transition: all 0.05s ease;
}

label.checkboxslider.checked .fs-checkbox_mask-7{
	transition: all 0.3s ease;
}

.shop-filter-switch-row-wrap {
	grid-column-gap: 1rem;
	grid-row-gap: 1rem;
	flex-direction: column;
	display: flex;
}

.fs-reset-1 {
	border: 2px solid #000;
	border-radius: 999rem;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	display: flex;
}

.fs-reset-1:hover {
	opacity: .7;
}

.fs-reset-1:focus-visible {
	outline-offset: 4px;
	outline: 2px solid #9b9b9b;
}

.fs-reset-1[data-wf-focus-visible] {
	outline-offset: 4px;
	outline: 2px solid #9b9b9b;
}

.fs-reset_icon-1 {
	width: 2rem;
	margin-right: .5rem;
}

.fs-reset_text-1 {
	font-weight: 500;
}

.shop-filter-reset {
	color: $white;
	margin-top: 2rem;
	display: inline-block;
}

.shop-filter-title {
	font-family: Bodoni, sans-serif;
}

.shop-filter-title.is--secondary {
	color: $lightblue2;
}

.shop-filter-block-header-icon {
	height: .5rem;
	transform: rotate(180deg);
	transition: all 0.3s ease;
}

.shop-product-card-vakgebied,
.shop-product-card-price {
	color: $lightblue1;
}

.shop-product-card-bottom {
	width: 100%;
	justify-content: space-between;
	align-items: flex-end;
	display: flex;
	flex-flow: row wrap;
	&.hasinpputnumber{
		display:block;
		small{
			margin-top: 5px;
  			display: inline-block;
			margin-left: 55px;
		}
	}
}

.shop-product-card-availability-wrap{
	margin-left:auto;
}

.shop-product-card-availability, .shop-product-card-warning {
	grid-column-gap: .3rem;
	grid-row-gap: .3rem;
	align-items: center;
	font-size: .8rem;
	display: flex;
}

.shop-product-card-warning {
	text-transform: none;
	justify-content: flex-end;
	margin-left: 30px;
  	text-align: right;
	align-items: flex-end;
	div{
		max-width: 95px;
	}
	img{
		margin-bottom:3px;
	}
}

.shop-product-card-availability-icon, shop-product-card-warning-icon {
	height: .8rem;
	grid-column-gap: 4px;
	grid-row-gap: 4px;
	align-items: center;
	display: flex;
}

.shop-product-list-item {
	flex: none;
}

.shop-product-card-top {
	grid-column-gap: 1rem;
	grid-row-gap: 1rem;
	flex-direction: column;
	display: flex;
	margin-bottom: 12px;
}

.fs_accordion-1_message {
	color: #9b9b9b;
}

.fs_accordion-1_paragraph {
	margin-bottom: 0;
}

.fs_accordion-1_content {
	overflow: hidden;
}

.fs_accordion-1_icon {
	margin-right: 0;
	font-size: 1.125rem;
	position: relative;
}

.fs_accordion-1_label {
	font-size: 1.25rem;
	font-weight: 500;
}

.fs_accordion-1_header {
	grid-column-gap: 1rem;
	grid-row-gap: 1rem;
	cursor: pointer;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 1.1rem;
	display: flex;
}

.fs_accordion-1_header:focus-visible {
	outline-offset: 4px;
	outline: 2px solid #9b9b9b;
}

.fs_accordion-1_header[data-wf-focus-visible] {
	outline-offset: 4px;
	outline: 2px solid #9b9b9b;
}

.fs_accordion-1_embed {
	margin-bottom: 0;
	display: none;
}

.fs_accordion-1_component {
	grid-column-gap: 1rem;
	grid-row-gap: 1rem;
	grid-template-rows: auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	display: grid;
}

.shop-product-card-extra {
	display: none;
}

.shop-pagination {
	background-color: $white;
	border-radius: 0px;
	margin-top: 2px;
	padding: 4rem 1rem;
	.paginationmainline{
		justify-content: center;
	}
	
	.paginationsubline{
		text-align: center;
		color:$lightblue1;
		margin-top:10px;
	}
}



.pagination_page-design {
	grid-column-gap: .5rem;
	grid-row-gap: 0;
	grid-template-rows: auto;
	grid-template-columns: auto;
	grid-auto-columns: auto;
	grid-auto-flow: column;
	align-items: center;
	margin-left: 0;
	margin-right: 0;
	display: grid;
}

.pagination_page-button {
	width: 30px;
	height: 30px;
	color: $darkblue;
	background-color: rgba(0, 0, 0, 0);
	border: 1px #dae4d8;
	border-radius: 100vw;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	display: flex;
}

a.pagination_page-button, .pagination_page-button {
	color: $darkblue;
}

.pagination_page-button.w--current {
	opacity: 1;
	color: $white;
	cursor: not-allowed;
	background-color: #3c7c43;
	border-color: #3c7c43;
}

.pagination_page-button._w--current {
	color: $white;
	background-color: $darkblue;
}

.fs-cmsload_page-dots {
	width: 3rem;
	height: 3rem;
	color: $darkblue;
	justify-content: center;
	align-items: center;
	font-size: 1.2rem;
	display: flex;
}

.pagination-next {
	width: 30px;
	height: 30px;
	color: $darkblue;
	background-color: rgba(0, 0, 0, 0);
	border-style: none;
	border-radius: 100vw;
	justify-content: flex-end;
	align-items: center;
	margin-left: 0;
	margin-right: 0;
	padding: 0;
	font-size: 1rem;
	text-decoration: none;
	display: flex;
	&.w-pagination-previous{
		justify-content: flex-start;
	}
}

a.pagination-next {
	color:$blue;
	font-size: 12px;
}


.shop-filter-form-wrap {
	margin-bottom: 0;
}

.nav-menu-text-block {
	font-size: .8rem;
}

.nav-filter-dropdown-student {
	height: 3.2rem;
	min-width: 12.5rem;
	border-radius: 4px;
	display: none;
}

.nav-filter-dropdown-student._w--open {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.nav-filter-dropdown-student.is--hidden {
	display: none;
}

.nav-filter-dropdown-student.is--disabled {
	opacity: .6;
	cursor: not-allowed;
}

.nav-filter-dropdown-opleider {
	height: 3.2rem;
	min-width: 12.5rem;
	border-radius: 4px;
	display: none;
}

.nav-filter-dropdown-opleider._w--open {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.nav-filter-dropdown-opleider.is--hidden {
	display: none;
}

.nav-filter-dropdown-opleider.is--disabled {
	opacity: .6;
	cursor: not-allowed;
}

.nav-filter-dropdown-group {
	margin-top: 1rem;
}

.nav-filter-dropdown-group-heading {
	color: rgba(255, 255, 255, .5);
	padding: 2px 0px 4px 0px;
	font-size: 10px;
	cursor:default;
}

/*
.nav-filter-dropdown-group-heading:hover {
	color: $white;
}
*/

.nav-filter-dropdown-tedoen {
	height: 3.2rem;
	min-width: 12.5rem;
	border-radius: 4px;
	display: none;
}

.nav-filter-dropdown-tedoen._w--open {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.nav-filter-dropdown-tedoen.is--hidden {
	display: none;
}

.nav-filter-dropdown-tedoen.is--disabled {
	opacity: .6;
	cursor: not-allowed;
}

.detail-sidebar-content {
	grid-column-gap: 4px;
	grid-row-gap: 4px;
	flex-direction: column;
	display: flex;
	position: -webkit-sticky;
	position: sticky;
	top: 0svh;
	z-index:1;
	padding-top: 8px;

	.detail-sidebar-item {
		grid-row-gap: 8px;
	}

	h5, h6 {
		color: $white;
		&.text-lightblue2{
			color: $lightblue2;
		}
	}

	h5 {
		margin-bottom: 4px;
		margin-top:0px;
	}

	h6 {
		margin-top:0px;
		margin-bottom:0px;
	}

	a {
		color: $white;
	}

	p {
		color: $lightblue2;
	}
}

@media screen and (max-width: 767px) {
	.stack-heading-arrow {
		height: 1.5rem;
	}

	.fs-checkbox_row {
		grid-auto-flow: row;
	}
}

@media screen and (max-width: 479px) {
	.stack-heading-arrow {
		height: 1.2rem;
	}

	.stack-heading-wrap {
		grid-column-gap: 1rem;
	}

	.stack-card {
		padding: 1rem;
	}

	.pagination_page-button,
	.fs-cmsload_page-dots {
		width: 2rem;
	}
}

#nodeHomeBottomRight {
	display: block;
}





#w-node-b9931379-475b-73a5-0427-ea5453641d53-183057c2 {
	grid-area: span 1 / span 1 / span 1 / span 1;
}


.wf-force-outline-none[tabindex="-1"]:focus {
	outline: none;
}

.page-wrapper {
	overflow: clip;
	background-color: $blue;
}

.nav {
	transform-style: unset !important;
}

.is--disabled {
	pointer-events: none;
}

.pagination_page-button.w--current {
	background-color: $blue !important;
	border: none !important;
}


.home-hero-section .imageContainer.home-hero-img {
	width: 100lvw;
	height: 100lvh;
	background-repeat: no-repeat;
	background-size: cover;
}

.imageContainer.stack-card-img {
	background-size: cover;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	min-height: 192px;
	background-position: center;
	border-radius: 4px;
	&.showFullImage{
		background-size: contain;
	}
}

.stack-component .gridnode1 p {
	color: $lightblue3;
}

.stack-card-content {
	h5.subheaderlabel {
		display: block;
		color: $lightblue1;
	}
	h5{
		color:$blue;
		margin-top:0px;
		margin-bottom:1px;
	}
}

.stack-card-content h3 {
	color: $blue;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
}

.stack-card-content div {
	padding-right:25px;
}

.home-hero-section,
.detail-hero-section {
	.w-icon-arrow-down {
		position: absolute;
		left: 49%;
		bottom: 40px;
		font-size: 10px;
	}
}

.section .container {
	padding-left: 4rem;
	padding-right: 4rem;
}

.section.nieuwsbrief {
	padding-top: 3rem;
}

.section.nieuwsbrief .container {
	.row1 {
		opacity: 0.5;
		color: $white;
		font-family: Bodoni, sans-serif;
		font-size: 90px;
		font-weight: 400;
		line-height: 90px;
	}

	.row2 {
		color: $white;
		font-family: Bodoni, sans-serif;
		font-size: 90px;
		font-weight: 400;
		line-height: 90px;
		margin: 22px 0px 46px 0px;
	}

	.row3 {
		fieldset {
			padding: 8px 14px 14px 14px;
			margin-top: -6.5px;
			display: inline-block;

			legend {
				color: white;
				margin-left: 2px;
				padding: 0px 3px 0px 3px;
			}

			input {
				border: none;
				margin: 0;
				padding: 0;
				height: 14px;
				background: none;
				width: 175px;

				&:focus {
					outline: none;
				}

			}
		}
	}

}

.section.footer {
	padding-bottom: 1rem;
	position: relative;
}

input[type="text"], input[type="number"], input[type="email"], input[type="password"], input[type="tel"]{
	&.textinputround {
		color: $white;
		border: 1px solid rgba(255, 255, 255, .6);
		border-radius: 100vw;
		padding: 14px;
		text-decoration: none;
		font-size: 10px;
		font-weight: 500;
		line-height: 14px;
		background: none;

		&:focus {
			outline: none;
		}
	}
}

.roundedfieldset {
	color: $white;
	border: 1px solid rgba(255, 255, 255, .6);
	border-radius: 100vw !important;
	padding: 14px;
	text-decoration: none !important;
	font-size: 10px !important;
	font-weight: 500 !important;
	line-height: 14px !important;
}

.section.footer .container {
	padding-left: 1rem;
	padding-right: 1rem;

	a.button-2{
		&:hover{
			background: rgba(255, 255, 255, 0.8);
		}
	}
	.block {
		background: $black;
		color: rgba(255, 255, 255, 0.7);
		padding: 46px 16px 46px 16px;
		border-radius: 4px;

		.row1 {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: flex-start;
			align-items: stretch;
			align-content: stretch;

			.logo,
			.directnaar,
			.webshop,
			.contact,
			.opdehoogte {
				display: block;
				flex-grow: 0;
				flex-shrink: 1;
				flex-basis: auto;
				align-self: auto;
				order: 0;

				.title {
					margin-bottom: 6px;
					h6{
						margin:0;
						font-size:18px;
						font-weight:600;
						color: rgba(255, 255, 255, 1);
					}
				}

				a {
					font-size: 16px;
					line-height: 22px;
					font-weight: 500;
					color: rgba(255, 255, 255, 0.7);
					padding: 3px 0px 2px 0px;
					margin: 7px 0px 7px 0px;
					text-decoration: underline;
					display: inline-block;
				}
			}

			.logo {
				width: 64px;
				height: 97px;
				background-image: url("./img/svh-logo.png");
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				opacity: 1;
			}

			.logo,
			.directnaar,
			.webshop,
			.contact {
				margin-right: 80px;
			}

			.contact {
				flex-grow: 1;
			}

			.opdehoogte {
				margin-right: 60px;

				.text {
					margin-top: 10px;
					font-size:16px;
				}

				.inputrow {
					margin: 18px 0px 7px 0px;

					a {
						margin: 0px;
					}

					input {
						color: rgba(255, 255, 255, 0.7);
					}
				}

				.socials {
					margin-top: 45px;

					a {
						display: inline-block;
						width: 17px;
						height: 17px;
						margin-right: 20px;
						background-size: 17px 17px;
						background-repeat: no-repeat;

						svg {
							width: 17px;
							height: 17px;
						}

					}

					a:last-child {
						margin-right: 0px;
					}
				}

			}
		}

		.row2 {
			margin-top: 46px;

			div {
				display: inline-block;
				margin-right: 16px;

				a {
					font-size: 14px;
					line-height: 18px;
					font-weight: 500;
					color: rgba(255, 255, 255, 0.7);
					padding: 3px 0px 2px 0px;
					text-decoration: none;
					display: inline-block;
				}

			}

			div:last-child {
				margin-right: 0px;
			}
		}

	}
}

.home-hero-right {
	font-size: 14px;
	line-height: 20px;
	font-weight: 500;
}

.home-direct-to{
	color: $white;
	margin-bottom:8px;
	h6{
		margin:0px;
	}
}

.home-hero-right-btn-wrap {
	font-size: 10px;
	line-height: 14px;
	font-weight: 500;
}

.shop-filter-sidebar{
	.close-icon{
		display:none;
	}
}

.shop-products{
	.filterbuttonwrap{
		display:none;
	}
}

.filterapplybuttonwrap{
	display:none;
}



ul, ol {
	margin-top: 0;
	margin-bottom: 10px;
	padding-left: 45px;
}

li{
	padding: 8px 0px 0px 8px;
}


ol {
	counter-reset: item;
	margin-left: 0;
	padding-left: 0;

	li {
		display: block;
		margin-bottom: 0px;
		margin-left: 46px;
	}
	li::before {
		display: inline-block;
		content: counter(item, decimal-leading-zero) " ";
		counter-increment: item;
		width: 2em;
		margin-left: -2em;
		font-size:80%;
		font-weight:500;
	}
}

blockquote{
	padding-top: 6px;
	padding-left: 35px;
	display: inline-block;

	span{
		display:block;
		padding-top: 4px;
	}
}

.infodoelgroep{
	background: white;
	color: $blue;
	padding: 18px 24px;
	border-radius: 8px;
	position: absolute;
	width: 431px;
	margin-top: 20px;
	transition: all 1s ease;
	.title{
		font-weight:600;
		font-size:20px;
		line-height:28px;
		margin-bottom:5px;
		position: relative;
		z-index: 1;
	}
	.text{
		font-weight:400;
		font-size:18px;
		line-height:25px;
		position: relative;
		z-index: 1;
	}

	.triangle {
		border-radius: 7px;
		-webkit-transform: rotate(45deg);
		-moz-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		-o-transform: rotate(45deg);
		transform: rotate(45deg);
		overflow: hidden;
		background:white;
		width:30px;
		height:30px;
		position: absolute;
  		top: -10px;
		z-index: 0;
	}
	
}

.nav-filter-dropdown.w-dropdown.active.level1.opened .infodoelgroep{
	opacity:0;
}

.nawinputs .inputwrap .input-type-radio{
	display: flex;
	justify-content: space-evenly;
	align-items: center;
  	height: 100%;
	.radioOption{
		display: flex;
		label{
			margin:0px 0px 0px 5px;
			color: #7398C9;
			font-family: Ibmplexsans, sans-serif;
			font-size: 14px;
  			font-weight: 500;
		}
	}
}


@media screen and (max-width: 1024px) {
	.detail-content-block {
		.photoTextBlocks.fourcols {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}
	}
}


@media screen and (max-width: 767px) {


	.home-hero-grid .detail-hero-content .home-hero-right-btn-wrap,
	.home-hero-grid .home-hero-right .home-direct-to,
	.home-hero-grid .home-hero-right .home-hero-right-btn-wrap {
		display:none;
	}

	.main-content .home-hero-section .container{
		padding-left: 1rem;
		padding-right: 1rem;
		.home-hero-grid{
			h1{
				max-width:none;
				padding-left:0;
			}
			.detail-hero-content {
				width: auto;
				max-width: none;
				margin-left: auto;
				margin-right: auto;
				padding-left: 0;
				p{
					max-width:none;
				}
			}
			.home-hero-right{
				max-width:none;
				padding-left:0;
			}

		}
	}

	.main-content .home-hero-section.noImage .container{
		#nodeHomeBottomRight {
			margin-bottom: 0px;
		}
	}
	




	h1 {
		font-size: 46px;
		line-height: 46px;
	}

	.main-content .container {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.home-hero-grid {
		max-width:unset;
	}

	#nodeHomeBottomRight {
		justify-content: left;
		margin-bottom: 30px;
	}

	.nav-filter,
	.nav-item.search,
	.nav-search-wrap,
	.nav-item.profile,
	.nav-item.cart {
		display: none;
	}

	.nav-menu-block {
		width: 100%;
	}

	.nav-menu-block.is--secondary {
		margin-top: 6px;
		width: auto;
		margin-right: auto;
	}

	.nav-menu-component, .profile-menu-component, .profileloggedin-menu-component, .cart-menu-component {
		grid-column-gap: 2px;
		grid-row-gap: 2px;
	}

	.textleftwith4pics .w-layout-grid {
		grid-template-columns: auto;
		grid-template-rows: auto;

		.gridnode1,
		.gridnode2,
		.gridnode3,
		.gridnode4,
		.gridnode5 {
			grid-area: unset;
		}
	}

	.stack-card {
		position: relative;
		padding: 1rem 0 1rem 0;
	}

	.section .container {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.section.nieuwsbrief {
		padding-top: 6rem;
		padding-bottom: 6rem;
	}

	.section {
		padding-top: 0;
		&.smallpaddingtopmobile{
			padding-top: 4rem;
		}
	}

	.stack-component {
		grid-row-gap: 4rem;
	}

	.stack-card.is-last {
		top: 6rem;
	}

	.textrightwithbigpic .w-layout-grid {
		height: auto;
		grid-template-rows: auto;
		grid-template-columns: auto;

		.gridnode1 {
			grid-row-start: 1;
			grid-column-start: 1;
			grid-row-end: 2;
			grid-column-end: 2;
			height: 200px;
		}

		.gridnode2 {
			grid-row-start: 2;
			grid-column-start: 1;
			grid-row-end: 3;
			grid-column-end: 2;
		}

		.gridnode3 {
			grid-row-start: 4;
			grid-column-start: 1;
			grid-row-end: 5;
			grid-column-end: 2;
			background: $white;
			margin-top: -4px;
			padding: 40px 32px 40px 16px;

			.button-2,
			a.button-2 {
				color: $blue;
				border-color: rgba(115, 152, 201, 0.3);
			}
		}

		.gridnode4 {
			grid-row-start: 3;
			grid-column-start: 1;
			grid-row-end: 4;
			grid-column-end: 2;

			.block {
				flex: unset;
				padding: 40px 16px 0px 16px;
				width: 100%;
			}
		}
	}

	.textleftwithbigpic .w-layout-grid {
		grid-template-rows: auto;
		grid-template-columns: auto;

		.gridnode1 {
			grid-row-start: 1;
			grid-column-start: 1;
			grid-row-end: 2;
			grid-column-end: 2;

			.mt-1 {
				display: none;
			}
		}

		.gridnode1b {
			grid-row-start: 2;
			grid-column-start: 1;
			grid-row-end: 3;
			grid-column-end: 2;
			padding: 4px 4px 16px 4px;

			.imageContainer {
				height: 180px;
				border-radius: 4px;
			}

			.mt-1 {
				padding:16px 10px 0px 10px;
			}
			.button-2{
				margin-top:30px;
			}
			.mt-1.hasMaxHeight{
				height: auto;
				overflow: unset;
			}
			.mt-1.buttonPlaceHolder{
				padding-top:0px;
			}
		}

		.gridnode2 {
			display:none;
		}

		.gridnode3 {
			grid-row-start: 3;
			grid-column-start: 1;
			grid-row-end: 4;
			grid-column-end: 2;
		}

		.gridnode4 {
			grid-row-start: 4;
			grid-column-start: 1;
			grid-row-end: 5;
			grid-column-end: 2;
		}

		.gridnode5 {
			grid-row-start: 5;
			grid-column-start: 1;
			grid-row-end: 6;
			grid-column-end: 2;
		}

		.gridnode6 {
			grid-row-start: 6;
			grid-column-start: 1;
			grid-row-end: 7;
			grid-column-end: 2;
		}

		.gridnode7 {
			grid-row-start: 7;
			grid-column-start: 1;
			grid-row-end: 8;
			grid-column-end: 2;
		}
	}

	.section.nieuwsbrief .container .row1,
	.section.nieuwsbrief .container .row2 {
		font-size: 46px;
		line-height: 46px;
	}

	.section.nieuwsbrief .container .row2 {
		margin: 2px 0px 32px 0px;
	}

	.section.nieuwsbrief .container .row3 fieldset {
		margin-top: 0px;
		margin-bottom: 16px;
		width: 100%;
	}

	.section.footer .container .block .row1 {
		display: block;

		.logo,
		.directnaar,
		.webshop,
		.contact,
		.opdehoogte {
			margin-right: 0px;
		}

		.directnaar,
		.webshop,
		.contact,
		.opdehoogte {
			margin-top: 40px;
			.socialsIconWrap {
				display: flex;
				justify-content: space-between;
			}
		}

		.opdehoogte .inputrow {
			margin: 18px 0px 7px 0px;
			width: 100%;
			.textinputround {
				width: calc(100% - 100px);
			}
		}
	}

	.section.footer .container .block .row2 div {
		display: block;
		margin: 7px 0px 7px 0px;
	}

	.detail-content {
		padding-left: 1rem;
		padding-right: 1rem;
		width:100%;
	}

	.detail-hero-content {
		padding-left: 0;

		p {
			margin-bottom: 10px;
		}
	}

	.detail-content-block h3 {
		font-size: 30px;
		line-height: 30px;
	}

	.detail-tile {
		grid-template-columns: auto;
		grid-row-gap: 2rem;
	}

	.detail-sidebar.mobilebottom {
		grid-row-start: 2;
	}

	.detail-sidebar {
		grid-column-start: 1;
		grid-column-end: 2;
		width: calc(100vw - 32px);
		.detail-sidebar-content {
			position: relative;
			top: 0;
		}
	}

	.layered-content {
		padding: 0;
		.layered-content-inner {
			border-radius: 0;
			max-width: 100vw;
		}
	}

	.shop-products{
		.filterbuttonwrap{
			position:fixed;
			bottom:16px;
			height:42px;
			left: 50%;
			transform: translateX(-50%);
			z-index: 100;
			display:block;
			a.filterbutton, .filterbutton{
				color: $white;
				background-color: rgba(13, 33, 57, 0.4);
				border: 1px solid rgba(255, 255, 255, .5);
				border-radius: 100vw;
				padding: 14px;
				text-decoration: none;
				display: inline-block;
				font-size: 10px;
				font-weight:500;
				-webkit-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.3);
				-moz-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.3);
				box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.3);
				-webkit-backdrop-filter: blur(16px);
				backdrop-filter: blur(16px);
				cursor: pointer;
			}
		}
	}

	.shop-products-wrap{
		margin-top: 32px;
		.shop-product-card {
			max-width: calc(50vw - 19px);
			height:auto;
			.shop-product-card-name, .shop-product-card-vakgebied{
				line-height:19px;
			}
			.shop-product-card-bottom{
				display:block;
				.button-shop {
					display:inline-block;
					margin-bottom: 12px;
					margin-top: 12px;
					opacity:1;
				}
			}
		}
	}

	.shop-filter-sidebar{
		display:none;
		grid-area: unset;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background:$blue;
		z-index: 101;
		padding: 0px 16px 0px 16px;
		transform: translate3d(0,0,0);
		&.show{
			display:block;
		}
		.close-icon{
			display:block;
			width: 12px;
    		height: 12px;
			background-image: url("./img/closeiconWhite.svg");
			background-position: 50%;
			background-repeat: no-repeat;
			background-size: 12px;
			position: absolute;
			right: 35px;
			top: 35px;
			cursor:pointer;
		}
		.filterapplybuttonwrap{
			display:block;
			position:fixed;
			bottom:16px;
			height:42px;
			left: 50%;
			transform: translateX(-50%);
			z-index: 100;
			a.filterapplybutton, .filterapplybutton{
				color: $blue;
				background-color: $white;
				border: 1px solid rgba(255, 255, 255, .5);
				border-radius: 100vw;
				padding: 14px;
				text-decoration: none;
				display: inline-block;
				font-size: 10px;
				font-weight:500;
				-webkit-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.3);
				-moz-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.3);
				box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.3);
				cursor: pointer;
			}
		}
		.shop-filter-block {
			background: none;
		}
		.shop-filter-block:first-child{
			padding: 16px 0 32px 0;
		}
		.shop-filter-block:last-child{
			padding: 16px 0 16px 0;
		}
		.fs-checkbox_field-1{
			background:$darkblue;
			padding: 11px 8px 11px 8px;
			border-radius: 4px;
		}
		.fs-checkbox_label-1 {
			line-height: 14px;
			font-weight:500;
			font-size:10px;
		}
		.shop-filter-block-content{
			padding-left: 0;
  			padding-right: 0;
		}
		.fs_accordion-1_header {
			padding-left: 0;
  			padding-right: 0;
		}
		.shop-filter-search {
			background-color: rgba(13, 33, 57, 0.2);
		}
	}

	.detail-content-block {
		width: calc(100vw - 32px);
		padding: 46px 16px 46px 16px;
		.detailpagebigimagewrapper{
			height:220px;
		}
		.detailpagenormalimagewrapper{
			height:auto;
		}
		.detailpagebigimage{
			height:220px;
			width: 100vw;
			position: relative;
			margin: 16px 0px 16px -32px;
		}
		.detailpagenormalimage{
			height:auto;
			position: relative;
		}
		.detailpagesmallimage{
			height:420px;
			position: relative;
			top: auto;
			right: auto;
			width: calc(100vw - 32px);
			margin-left: -16px;
		}
		.webshopitems .shop-product-list-item {
			flex: 1 0 45%;
		}
		.shop-product-card {
			.shop-product-card-name, .shop-product-card-vakgebied{
				line-height:19px;
			}
			.shop-product-card-bottom{
				display:block;
				.button-shop {
					display:inline-block;
					margin-bottom: 12px;
					margin-top: 12px;
					opacity:1;
				}
			}
		}
		.photoTextBlocks .stack-card-innercard {
			flex: 1 0 45%;
			&.onecolumnmobile{
				flex: 1 0 80%;
			}
		}
		.threecolumnBlocks{
			grid-template-columns: repeat(1, minmax(0, 1fr));
		}
		.headerblockTwocolumns .detailpagebigrightimage {
			display: none;
		}
		.photoTextBlocks.fourcols {
			grid-template-columns: repeat(1, minmax(0, 1fr));
		}
	}

	.layered-content {
		.detail-content-block {		
			width: 100%;
		}
	}

}

/*
.detail-hero-section .imageContainer{
	&:after{
		content:"";
		background:#008AD1;
		top:0;
		left:0;
		right:0;
		bottom:0;
		position:absolute;
		opacity: 0;
	}
}
*/
.breadcrumb{
	padding-top:17px;
	margin-left:21px;
	padding-bottom:15px;
	a{
		color:white;
	}
	div{
		line-height:14px;
		display:inline-block;
		background-image: url("./img/breadcrumbicon.svg");
		background-position: bottom 2px right;
		background-repeat: no-repeat;
		margin-right: 4px;
		padding-right: 19px;
		&:last-child{
			background-image:none;
			padding-right:0;
			margin-right:0;
		}
	}
}

.select{
	position: relative;
	select {
		display: block;
		position: relative;
		background: #056094;
		cursor: pointer;
		padding: 14px 35px 14px 14px;
		border: 1px solid $lightblue1;
		width: 100%;
		font-family: Ibmplexsans, sans-serif;
		font-size: 10px;
		font-weight: 500;
		border-radius: 4px;
		margin: 0;
		-webkit-appearance: none;
		-moz-appearance: none;
		-o-appearance: none;
		appearance: none;
		&::-ms-expand {
			display: none;
		}
	}
	&::after {
		content: url('./img/selectArrowWhite.svg');
		width: 10px;
		height: 10px;
		position: absolute;
		top: 13px;
		right: 14px;
		pointer-events: none;
	}
}


.main-content.webshopproduct.projectexamen{
	.form-input-result.input-time{
		.inputContainer {
			font-size:0px;
			position: relative;
  			display: inline-block;
			input[type="text"]{
				width: 43px;
				display: inline-block;
				padding-left:12px;
				padding-right:12px;
				&:first-child{
					border-right: none;
					border-radius: 2px 0px 0px 2px;
				}
				&:last-child{
					border-left: none;
					border-radius: 0px 2px 2px 0px;
				}
			}
			&:after {
				content: ":";
				position: absolute;
				top: 0;
				margin-left: -3px;
				font-size: 18px;
				color: #ffffff;
				line-height: 47px;
				left: 50%;
			}
		}
	}
}


.main-content.webshopproduct{
	.productStickyCTAwrap{
		position: fixed;
		bottom: -120px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 100;
		display: flex;
		padding: 16px;
		width: 512px;
		align-items: center;
		font-family: Ibmplexsans, sans-serif;
		border-radius:2px;
		background-color:$black;

		.productImage{
			height:80px;
			min-width: 80px;
			.imageContainer.stack-card-img{
				min-height:80px;
				background-size: contain;
			}
		}

		.productDetail{
			flex:1;
			padding:0 8px 0 8px;
			overflow: hidden;
			h6{
				margin-top:0;
				margin-bottom:0;
			}
			.productTitle{
				color:white;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.productType{
				color:rgba(255,255,255,0.5);
			}
			.productPrice{
				color:white;
				margin-top:8px;
			}
		}
		.productBasket{
			.button-shop{
				opacity:1;
				color:white;
				border-color:white;
				font-size:10px;
				font-weight:500;
				cursor:pointer;
				div{
					background-image: url("./img/plusWhite.svg");
					background-position: center right;
					background-repeat: no-repeat;
					padding-right: 18px;
				}
			}
		}
	}

	.detail-tile{
		grid-template-columns: 1fr;
	}

	.productcontent-block-left{
		padding: 32px;
		grid-column-start: 1;
		grid-column-end: 2;
		grid-column-gap: 4px;
		grid-row-gap: 4px;
		flex-direction: column;
		display: flex;
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		z-index: 1;
		background:white;
		.imagePlaceholder{
			width:400px; 
			height:491px;
			margin-left: auto;
  			margin-right: auto;
		}
		.imageContainer {
			width: 100%;
			height: 100%;
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		}
	}
	.productcontent-block-right{
		position: relative;
		grid-column-start: 2;
		grid-column-end: 3;
		grid-row-start: 1;
		grid-row-end: 5;
	}

	.examcontent-block-left{
		padding: 0px 0px 0px 0px;
		grid-column-start: 1;
		grid-column-end: 2;
		grid-column-gap: 4px;
		grid-row-start: 2;
		grid-row-gap: 4px;
		flex-direction: column;
		display: flex;
		z-index: 1;
	}
	.examcontent-block-right{
		position: relative;
		grid-column-start: 2;
		grid-column-end: 3;
		grid-row-start: 2;
		grid-row-end: 5;
		position: -webkit-sticky;
		position: sticky;
		top: 0;

		.productcontent-content .darkbluesection{
			margin: 0px 0px 16px 0px;
		}

		.productcontent-content .productDetail .row .cell:first-child {
			width: 140px;
		}

	}

	.mainProductSection{
		h2{
			color:$white;
		}
		h2.subtitle{
			color:rgba(255,255,255,0.6);
		}
		&.itemTypeExam{
			grid-column: span 2;
		}
	}



	.productcontent-content{
		position:relative;
		top: unset;
		flex-direction: column;
		display: flex;
		z-index: 1;
		.mainProductSection{
			h2{
				color:$white;
			}
			h2.subtitle{
				color:rgba(255,255,255,0.6);
			}
		}
		.darkbluesection{
			background-color:#056094;
			border-radius:2px;
			padding:16px;
			margin:14px 0px 16px 0px;

			input[type="text"], input[type="number"], input[type="email"], input[type="password"], input[type="tel"], textarea{
				color:white;
				&::placeholder{
					color: rgba(255, 255, 255, .8);
					opacity: 1;
				}
				border: 1px solid #7398C9;
				&.missing{
					border-color: rgba(227, 57, 53, 0.7);
					border-width:3px;
				}
			}

			.smallnote{
				color:$lightblue2;
			}
			.button-shop{
				color:white;
				border: 1px solid $white;
				opacity:1;
				font-size: 10px;
  				font-weight: 500;
				cursor:pointer;
				div{
					background-image: url("./img/plusWhite.svg");
					background-position: center right;
					background-repeat: no-repeat;
					padding-right: 18px;
				}
			}
			.select{
				select {
					background: #056094;
					border: 1px solid $lightblue1;
					color:white;
					font-size: 16px;
  					padding: 11px 35px 11px 14px;
					&.missing{
						border-color: rgba(227, 57, 53, 0.7);
						border-width:3px;
					}
				}
				&::after {
					content: url('./img/selectArrowWhite.svg');
				}
			}
		}
		.whiteSection{
			background-color: $white;
			border-radius: 0px;
			padding: 16px 108px 46px 16px;
			position: relative;
		}
		.detail-content-block.related{
			margin-top:50px;
			.imageContainer.stack-card-img{
				background-size: contain;
			}
		}
		.productDetail{
			display:table;
			.row{
				display:table-row;
				.cell{
					display:table-cell;
					color:white;
					padding:3px 10px 4px 0px;
					&:first-child{
						color:$lightblue2;
						vertical-align: middle;
					}
					.button-shop{
						margin-top:12px;
						margin-bottom:6px;
					}
					h6{
						margin-top:0px;
						margin-bottom:0px;
					}
				}
			}
			
			.form-input-result{
				&.input-extra_docs, &.input-copy_id{
					.title, .value{
						padding-top:20px;
						padding-bottom:20px;
					}
				}
				display:table-row;
				.title, .value{
					display:table-cell;
					color:white;
					padding:3px 10px 4px 0px;
					h6{
						margin-top:0px;
						margin-bottom:0px;
					}
					h5{
						font-family: Ibmplexsans, sans-serif;
						font-size: 16px;
						font-weight: 500;
						line-height: 22px;
						letter-spacing: -0.02em;
						text-align: left;
						color:$lightblue2;
					}
					textarea {
						min-height: 105px;
						height: auto;
						width: 100%;
						color:white;
					}
				}
				.title{
					color:$lightblue2;
					vertical-align: middle;
					.subtitle{
						font-size:12px;
					}
				}
				
			}
			.orderInfo{
				color:$lightblue2;
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.main-content.webshopproduct{
		.detail-content{
			width: 95%;
			max-width: 1600px;
			grid-column-gap: 1rem;
			grid-row-gap: 4rem;
			flex-direction: column;
			margin-left: auto;
			margin-right: auto;
			position: relative;
		}
	}
}

@media screen and (max-width: 767px) {
	.main-content.webshopproduct{
		.detail-content {
			width:100%;
		}
		.breadcrumb {
			padding-top: 60px;
			padding-bottom: 0px;
			div{
				display:none;
			}
		}
		.detail-tile {
			display:block;
		}
		.productcontent-block-left {
			margin-bottom:32px;
			position:relative;
			top:unset;
			.imagePlaceholder{
				width:100%;
				height:270px;
			}
		}
		.productcontent-content{
			.mainProductSection{
				h2{
					padding: 12px 0px 8px 0px;
				}
			}
			.whiteSection{
				padding: 16px 16px 46px 16px;
			}
		}
		.productStickyCTAwrap {
			width: calc(100% - 16px);
			.productImage{
				height:50px;
				min-width:50px;
				.imageContainer.stack-card-img{
					min-height:50px;
				}
			}
			.productDetail{
				.productPrice{
					margin-top:0;
				}
			}
		}
	}
}

body.error404 .section.footer{
	padding-top: 1rem;
}

.input-radio {
	padding: 6px 0 15px 0;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	&.large label{
		font-size: 1.25rem;
	}
	label{
		display: block;
  		cursor: pointer;
	}
	input {
		display: none;
	}
	&.light span {
		margin: 5px 14px 3px 0;
	}
	span {
		width: 20px;
		height: 20px;
		display: inline-block;
		background: transparent;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		-ms-border-radius: 50%;
		border-radius: 50%;
		border: 1px solid $lightblue2;
		position: relative;
		margin: 3px 14px 3px 0;
	}
	p {
		display: inline;
	}

	&.light input:checked + span::before {
		background: $blue;
	}
	input:checked + span::before {
		content: "";
		width: 10px;
		height: 10px;
		position: absolute;
		margin: 4px;
		background: #13082a;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		-ms-border-radius: 50%;
		border-radius: 50%;
	}

}


.main-content.generic {
	&.contact{
		.content-section{
			margin-top: 16px;
			width: 983px;

			p{
				font-size:14px;
				line-height:20px;
				font-weight:400;
			}
			.morepadding{
				padding-bottom:8px;
			}
			.smallnote{
				font-size:12px;
				line-height:17px;
			}
			h5{
				margin-top: 31px;
				margin-bottom: 5px;
			}
			.whiteSection{
				padding: 46px 16px 46px 16px;
			}
			.cell{
				padding-right: 32px;
				&:last-child{
					padding-right: 0px;
				}
			}
		}
	}
	.row{	
		display: flex;
		.cell{	
			flex: 1;
		}
	}
	.detail-content.smallmargintop{
		margin-top: 1rem;
	}
	.detail-tile {
	  grid-row-gap: 1rem;
	}
	&.nieuwsitem{
		.content-section{
			width: 983px;
		}
	}
	.header-section{
		padding: 193px 64px 40px 64px;
		color:white;
		.date{
			margin-bottom:14px;
			font-size:18px;
			line-height:25px;
			font-weight:400;
			font-family: Ibmplexsans, sans-serif;
		}
	}
	.content-section{
		width: 763px;
		margin-left: auto;
		margin-right: auto;
		position: relative;
		margin-top: 53px;

		.detailpagebigimagewrapper {
			height: 554px;
			margin-bottom: 28px;
		}
		.detailpagenormalimagewrapper {
			height: auto;
			margin-bottom: 28px;
		}
		.detailpagenormalimage {
			height: auto;
			width: 100%;
			position: absolute;
			margin: -46px 0px 16px 0px;
			background-repeat: no-repeat;
			background-size: contain;
		}
		.detailpagebigimage {
			height: 554px;
			width: 983px;
			position: absolute;
			margin: -46px 0px 16px -16px;
			background-repeat: no-repeat;
			background-size: contain;
		}
		h5{
			color: $blue;
		}
		.whiteSection {
			background:white;
			padding: 46px 218px 46px 16px;
			width: 763px;
		}
	}
}

.main-content, .layered-content {
	a.download{
		color:$blue;
		background-image: url("./img/downloadBlue.svg");
		background-position: bottom 2px left;
		background-repeat: no-repeat;
		padding-left: 20px;
		margin-top: 8px;
		margin-bottom: 8px;
		display: inline-block;
	}
}

.whiteContentBlock, .whiteSection{
	h3,h4,h5,h6{
		color:$blue;

		&.text-lightblue {
			color: #b2caea;
		}
		&.text-lightblue2{
			color: $lightblue2;
		}

	}
}

.popupunlock{
	.errorWrapper{
		margin-top:16px;
		.error, .blocked, .locked{
			display:none;
		}
	}
}


@media screen and (max-width: 767px) {
	.main-content.generic {
		&.contact{
			.content-section{
				width: auto;
				.row{
					display: block;
				}
			}
		}
		&.nieuwsitem {
			.content-section{
				width: auto;
			}
		}
		.header-section{
			padding: 120px 16px 30px 16px;
			.date{
				display:none;
			}
		}
		.content-section{
			width: auto;
			margin-left: 16px;
			margin-right: 16px;
			margin-bottom: 16px;
			margin-top: 41px;
			.whiteSection{
				padding: 46px 16px 46px 16px;
				width:auto;
			}
			.detailpagebigimage {
			  height: 300px;
			  width: 100vw;
			  margin: -46px 0px 16px -32px;
			}
			.detailpagebigimagewrapper {
  				height: 285px;
				margin-bottom: 0px;
			}
		}
	}
}

.main-content.faq{
	.detail-tile{
		grid-template-columns: 20rem 1fr;
	}
	.detail-sidebar {
		grid-column-start: 1;
  		grid-column-end: 2;
		.detail-sidebar-content{
			top:32px;
			.detail-sidebar-item {
				border-radius: 0px;
				.select {
					margin-bottom:12px;
					select{
						font-size:14px;
						font-weight:500;
						background:#094066;
					}
				}
				a.button-1{
					font-size:10px;
					background: transparent;
					font-weight: 500;

					&:hover{
						background: rgba(13, 33, 57, .2);
					}

				}
				p.headertitle{
					font-weight:500;
				}
			}
		}
	}
	.detail-content-block {
		grid-column-start: 2;
		grid-column-end: 3;
	}
}


.aanvraagPresentExemplaarFormWrapper{
	.select select{
		background: $white;
		padding: 9px 20px 9px 8px;
		border: 1px solid rgba(115, 152, 201, 0.30);
		font-family: Ibmplexsans, sans-serif;
		font-size: 14px;
		font-weight: 500;
		line-height: 140%;
		letter-spacing: -0.28px;
	}
	.select{
		width:200px;
	}
	.select::after {
		content: url('./img/selectArrowBlue.svg');
		top: 0px;
		right: 8px;
	}
}


@media screen and (max-width: 767px) {
	.main-content.faq{
		.detail-tile{
			grid-template-columns: auto;
			grid-row-gap: 2rem;
		}
		.detail-content-block{
			width: calc(100vw - 32px);
    		padding: 0px 0px 0px 0px;
			grid-column-start: 1;
 			grid-column-end: 2;
		}
	}
}


.cart-menu-component{
	.nav-menu-block {
		width: 480px;
	}
}

.cart-menu-component{
	.menu-content{
		&.alignright{
			text-align:right;
		}
		a.button-1{
			margin-top:0;
		}
		.row{
			background:white;
			border-radius:4px;
			margin-bottom:2px;
			.cell{
				h5,h6{
					margin-top: 0px;
					margin-bottom: 0px;
				}
				.select{
					width:55px;
				}
				.select select{
					background: $white;
					padding: 9px 20px 9px 8px;
					border: 1px solid rgba(115, 152, 201, 0.30);
					font-family: Ibmplexsans, sans-serif;
					font-size: 14px;
					font-weight: 500;
					line-height: 140%;
					letter-spacing: -0.28px;
				}
				.select::after {
					content: url('./img/selectArrowBlue.svg');
					top: 8px;
					right: 8px;
				}

				width:auto;
				flex:unset;
				font-family: Ibmplexsans, sans-serif;
				font-size: 14px;
				font-weight: 500;
				line-height: 140%;
				letter-spacing: -0.28px;
				color: $blue;
				.productImgWrap{
					.shop-product-img{
						height: 110px;
						width: 80px;
						background-repeat: no-repeat;
						background-size: contain;
						margin-right:16px;
					}
				}
				.productDetailWrap{
					width: 100%;
					
					.removeicon{
						background-image: url("./img/trashcanBlue.svg");
						background-position: center;
						background-repeat: no-repeat;
						cursor:pointer;
						width: 17px;
						height: 19px;
						margin-top: 1px;
						float:right;
					}
					.countdownwrap{
						margin-top:16px;
						display:flex;
						align-items: center;
						.countdowntimer{
							border-radius: 4px;
							background: #FFB800;
							padding: 4px 16px 4px 26px;
							height: 24px;
							color: $black;
							font-family: Ibmplexsans, sans-serif;
							font-size: 10px;
							font-style: normal;
							font-weight: 500;
							line-height: 17px;
							letter-spacing: 0.1px;
							background-image: url("./img/timer-icon.svg");
							background-position: 14px 6px;
							background-repeat: no-repeat;
						}
						.countdownmessage{
							color: $lightblue1;
							max-width:210px;
							margin-left:6px;
						}
					}
					.mobilewrap{
						display:flex;
						justify-content: space-between;
						margin-top:24px;
						align-items: end;
						.headerrow{
							color: $lightblue1;
						}
						a{
							font-size: 10px;
							font-weight: 500;
							line-height: 140%; 
							text-decoration-line: underline;
							color: $blue;
						}
						> div:nth-child(2){
							opacity:0.5;
							color: #82B0CA;
						}
					}
					.shop-product-type{
						color: $lightblue1;
						opacity: 0.5;
					}
					
					.shop-product-ordernr{
						.mobile-shop-product-orderdate{
							display:block;
							float: right;
						}
					}

					.codewrap{
						margin-top:24px;
						.status{
							color: $lightblue1;
						}
					}
					.fs_accordion-1_header{
						padding:0;
						margin-top:8px;
						justify-content: unset;
						grid-column-gap: 6px;
					}
					.fs_accordion-1_item{
						display:inline-block;
					}
					.fs_accordion-1_content{
						display:none;
						height:0px;
						transform: translate3d(0,0,0);
					}
					.accordioncodewrap{
						font-size: 10px;
						font-weight: 500;
						line-height: 140%; /* 14px */
						letter-spacing: 0.1px;
						text-decoration-line: underline;
						display:inline-block;
					}
					.button-2, a.button-2{
						margin-top:32px;
						color:$blue;
						font-family: Ibmplexsans, sans-serif;
						letter-spacing: 0.1px;
						border: 1px solid rgba(115, 152, 201, 0.30);
						&.download{
							background-position: center right 12px;
							padding-right: 34px !important;
							margin-bottom:0;
						}
					}
				}

				&:first-child{
					width:auto;
					min-width:122px;
					flex:1;
					display:flex;
				}
			}

			&.headerrow{
				background:none;
				.cell{
					padding-bottom:11px;
					color:white;
					font-family: Ibmplexsans, sans-serif;
					font-size: 14px;
					font-weight: 500;
					line-height: 140%;
					letter-spacing: -0.28px;
				}
			}
		}
	}
}





.main-content.generic.myaccount .content-section .orders .row .cell{
	.itemwrapper{
		.product{
			display:flex;
			.productImgWrap{
				width:96px;
			}
			.productDetailWrap{
				flex:1;
			}
		}
	}
}

.main-content.webshopproduct .productcontent-content .productDetail .row .cell .title{
	display:none;
}

.main-content.webshopproduct .breadcrumb{
	padding-top:100px;
}

.main-content.webshopproduct .productcontent-content .darkbluesection .select::after {
	top: 9px;
}

.form-inputs .form-input-result .password-ruleset {
	padding: 15px;
	.rule {
		display: block;
		font-size: 14px;
		color: #ab3939;
		&:before {
			content: "";
			width: 5px;
			height: 5px;
			border-radius: 50%;
			background: #ab3939;
			display: inline-block;
			margin-right: 12px;
			vertical-align: middle;
		}
		&.active {
			color: #45ab48;
			font-weight: bold;
		}
	}
}

.form-input.missing{
	border-color: rgba(227, 57, 53, 0.7);
	border-width: 3px;
}
.input-type-checkbox.missing{
	color: rgba(227, 57, 53, 0.7);
}

.main-content.generic.checkout .content-section .detail-tile .table.orders .row.missing .fs-checkbox_toggle-7 .fs-checkbox_toggle-dot-7{
	background: rgba(227, 57, 53, 0.7);
}
.main-content.generic.checkout .content-section .detail-tile .table.orders .row.missing .fs-checkbox_toggle-7.checked .fs-checkbox_toggle-dot-7{
	background: #008AD1;
}

.main-content.generic.cart .content-section .detail-tile .detail-sidebar .detail-sidebar-content .detail-sidebar-item input[type="text"], 
.main-content.generic.checkout .content-section .detail-tile .detail-sidebar .detail-sidebar-content .detail-sidebar-item input[type="text"]{
	&.missing{
		border-color: rgba(227, 57, 53, 0.7);
		border-width: 3px;
	}
	&.succes{
		border-color: green;
		border-width:3px;
	}
	&.failed{
		border-color: rgba(227, 57, 53, 0.7);
		border-width:3px;
	}
}

.input-type-file .fileInput{
	overflow: hidden;
	width: 0;
	height: 0;
}

.btn-file.front-btn{
	max-width: 170px;
	line-height: 12px;
	margin-bottom: 12px;
	color: white;
	border: 1px solid #FFFFFF;
	opacity: 1;
	font-size: 10px;
	font-weight: 500;
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 100vw;
	padding: 0.8rem 1rem;
	text-decoration: none;
	transition: all 0.3s ease;
	transform: translate3d(0, 0, 0);
	&.missing{
		border-color: rgba(227, 57, 53, 0.7);
		border-width:3px;
	}
}
.main-content.webshopproduct .productcontent-content .darkbluesection .button-shop.disabled{
	color: #bbb;
  	border-color: #bbb;
}

.orderInfoOptional.hidden{
	display:none;
}

.registerFeedback{
	.succes, .noSucces{
		padding:25px;
		h3{
			margin-bottom: 10px;
		}
	}
	.noSucces{
		background-color: #ffecec;
		h3{
			color: #ed3030;
		}
	}
	.succes{
		background-color: #e6f6f1;
		h3{
			color: #0eab75;
		}
	}
}


body.register, .registers, .projectexamen{

	.form-input-result.input-dob .value{
		display:flex;

		.select{
			margin-right: 10px;
			&:last-child{
				margin-right: 0px;
			}
		}
	}

	.form-input.small{
		width:135px;
	}
	.form-input.big{
		width:100%;
	}
}

body.register .whiteSection, .registers.whiteContentBlock {
	.select{
		color: #7398C9;
		
		&.small{
			width:135px;
		}
		&.big{
			width:100%;
		}

	}
	.select select{
		background: $white;
		border: 1px solid rgba(115, 152, 201, 0.30);
		font-family: Ibmplexsans, sans-serif;
		font-size: 14px;
		font-weight: 500;
		line-height: 140%;
		letter-spacing: -0.28px;
		border-radius: 2px;
		padding: 14px;
	}
	.select::after {
		content: url('./img/selectArrowBlue.svg');
		top: 12px;
		right: 12px;
	}
}

.input-requestmethod{
	margin-top:20px;
}


.main-content.generic.myaccount{
	.content-section{
		.nawdata{
			.row.profileblock{	
				.gridwrapper{
					.form-section {
						.form-inputs{
							.column-md-4{
								align-content: end;
							}
							input[type="text"], input[type="number"], input[type="email"], input[type="password"], input[type="tel"], textarea, .select{
								width:100%;
							}
							.select{
								&:after{
									top:12px;
								}
								select{
									padding: 14px 20px 14px 14px;
								}
							}
						}
					}
					.form-interactions.end-form.edit{
						margin-top:30px;
					}
				}
			}
		}
	}
}

.main-content.generic.myaccount, .main-content.generic.cart, .main-content.generic.checkout{ 
	.header-section{
		padding-top:160px;
	}
	.content-section{
		.detail-tile{
			margin-left:16px;
			margin-right:16px;
		}
		max-width: 1091px;
		width:auto;
		margin-top: 10px;
		.orders, .nawdata{
			.loadmore{
				background-color:#03679B;
				color:white;
				border-radius: 4px;
				margin-top:2px;
				text-align: center;
				padding: 32px 32px 32px 16px;
				display: flex;
				align-items: center;
				font-size:14px;
				font-weight:500;
				justify-content: center;
				cursor:pointer;
				&:hover{
					border-radius: 24px;
				}
			}
			h5,h6{
				margin-top:0px;
				margin-bottom:0px;
			}
			min-width:600px;
			.select{
				width:55px;
			}
			.select select{
				background: $white;
				padding: 9px 20px 9px 8px;
				border: 1px solid rgba(115, 152, 201, 0.30);
				font-family: Ibmplexsans, sans-serif;
				font-size: 14px;
				font-weight: 500;
				line-height: 140%;
				letter-spacing: -0.28px;
			}
			.select::after {
				content: url('./img/selectArrowBlue.svg');
				top: 8px;
				right: 8px;
			}
			.row{
				background:white;
				border-radius:4px;
				margin-bottom:2px;
				.cell{
					width:122px;
					padding:16px;
					flex:unset;
					font-family: Ibmplexsans, sans-serif;
					font-size: 14px;
					font-weight: 500;
					line-height: 140%;
					letter-spacing: -0.28px;
					color: $blue;
					.productImgWrap{
						.shop-product-img{
							height: 110px;
							width: 80px;
							background-repeat: no-repeat;
							background-size: contain;
							margin-right:16px;
						}
					}
					.productDetailWrap{
						.mobile-shop-product-remove{
							display:none;
						}
						.countdownwrap{
							margin-top:16px;
							display:flex;
							align-items: center;
							.countdowntimer{
								border-radius: 4px;
								background: #FFB800;
								padding: 4px 16px 4px 26px;
								height: 24px;
								color: $black;
								font-family: Ibmplexsans, sans-serif;
								font-size: 10px;
								font-style: normal;
								font-weight: 500;
								line-height: 17px;
								letter-spacing: 0.1px;
								background-image: url("./img/timer-icon.svg");
								background-position: 14px 6px;
								background-repeat: no-repeat;
							}
							.countdownmessage{
								color: $lightblue1;
								max-width:210px;
								margin-left:6px;
							}
						}
						.mobilewrap{
							display:none;
						}
						.shop-product-type{
							color: $lightblue1;
							opacity: 0.5;
						}
						.shop-product-ordernr{
							.mobile-shop-product-orderdate{
								display:none;
							}
						}
						.codewrap{
							margin-top:15px;
							.status{
								color: $lightblue1;
							}
						}
						.fs_accordion-1_header{
							padding:0;
							margin-top:8px;
							justify-content: unset;
							grid-column-gap: 6px;
						}
						.fs_accordion-1_item{
							display:inline-block;
						}
						.fs_accordion-1_content{
							display:none;
							height:0px;
							transform: translate3d(0,0,0);
						}
						.accordioncodewrap{
							font-size: 10px;
							font-weight: 500;
							line-height: 140%; /* 14px */
							letter-spacing: 0.1px;
							text-decoration-line: underline;
							display:inline-block;
						}
						.button-2, a.button-2{
							margin-top:32px;
							color:$blue;
							font-family: Ibmplexsans, sans-serif;
							letter-spacing: 0.1px;
							border: 1px solid rgba(115, 152, 201, 0.30);
							&.download{
								background-position: center right 12px;
  								padding-right: 34px !important;
								margin-bottom:0;
							}
						}
					}

					&:first-child{
						width:auto;
						min-width:122px;
						flex:1;
						display:flex;
					}
					&:last-child{
						width:112px;
						a{
							font-size: 12px;
							font-weight: 500;
							line-height: 140%; /* 19.6px */
							text-decoration-line: underline;
							color: $blue;
						}
					}
				}

				&.headerrow{
					background:none;
					.cell{
						padding-bottom:11px;
						color:white;
						font-family: Ibmplexsans, sans-serif;
						font-size: 14px;
						font-weight: 500;
						line-height: 140%;
						letter-spacing: -0.28px;
					}
				}
			}
		}

		.nawdata{
			margin-bottom:80px;

			.row.headerrow .cell:last-child{
				width:250px;
				text-align: right;
				h6{
					text-align: right;
				}
				a h6{
					color:white;
					text-decoration: underline;
				}
			}

			.row.profileblock{
				background:transparent;
				.gridwrapper{
					padding:0px;
					width:100%;
					
					.form-section-title{
						display:block;
						padding:16px;
						margin-top: 10px;
						
						h5{
							color:white;
							margin-top: 0;
							margin-bottom: 0;
							font-family: Bodoni, sans-serif;
							font-size: 24px;
							font-weight: 400;
							line-height: 26px;
							letter-spacing: -0.02em;
						}
					}
					.input-errors.hidden{display:none;}
					.form-section {
						margin-bottom: 0px;
						display:none;
						&.personal{
							display:block;
							.form-section-title{
								display:none;
							}
						}
						&:last-child{
							margin-bottom: 0px;
						}
						.form-inputs{
							background:white;
							border-radius: 4px;
							padding:16px;
							grid-template-columns: repeat(3, minmax(0, 1fr));
							grid-column-gap: 16px;
							grid-row-gap: 32px;
							align-items: stretch;
							display: grid;
							width: 100%;
							
							
							.svhTooltip {
								display:none;
							}
						}
					}
					&.moreloaded{
						.loadmore{
							display:none;
						}
						.form-section {
							display:block;
						}
					}
				}
			}
		}

		.whiteSection {
			padding: 16px 16px 16px 16px;
			width: 1059px;
		}
	}
}

.main-content.generic.myaccount .content-section .orders .row .cell .productDetailWrap {
	.button-2, a.button-2 {
		margin-top: 12px;
	}
}

.main-content.generic.myaccount .content-section .orders{
	.row{
		&:nth-of-type(1n+7) {
			display: none;
		}
	}
	&.moreloaded{
		.row{
			&:nth-of-type(1n+7) {
				display: flex;
			}
		}
		.loadmore{
			display:none;
		}
	}
}



@media screen and (max-width: 767px) {
	.main-content.generic.myaccount, .main-content.generic.cart, .main-content.generic.checkout{ 
		.content-section{
			.detail-tile{
				margin-left:0px;
				margin-right:0px;
			}
			.orders{
				min-width: unset;
				.row{
					.cell{
						.productDetailWrap{
							width: 100%;
							.codewrap{
								margin-top:24px;
							}
							.mobilewrap{
								display:flex;
								justify-content: space-between;
								margin-top:24px;
								.headerrow{
									color: $lightblue1;
								}
								a{
									font-size: 10px;
									font-weight: 500;
									line-height: 140%; 
									text-decoration-line: underline;
									color: $blue;
								}
							}
							.shop-product-ordernr{
								.mobile-shop-product-orderdate{
									display:block;
									float: right;
								}
							}
						}

						&:nth-child(2){
							display:none;
						}
						&:nth-child(3){
							display:none;
						}
						&:nth-child(4){
							display:none;
						}
						&:nth-child(5){
							display:none;
						}
					}
					&.headerrow{
						display:none;
					}
				}
			}
		}
	}
}

.main-content.generic.cart, .main-content.generic.checkout{
	

	.header-section{
		padding-top:160px;
	}
	.content-section{
		max-width: 1122px;

		.detail-tile{
			grid-column-gap: 2px;
	
			&.hasHeaderTitles{
				.detail-sidebar{
					.detail-sidebar-content{
						margin-top: 43px;
					}
				}
			}
	
			.detail-sidebar{
				grid-row-end: 2;
				.detail-sidebar-content{
					top: 0px;
					grid-column-gap: 2px;
					grid-row-gap: 2px;
					.detail-sidebar-item{
						grid-row-gap: 8px;
	
						&.linkarrow{
							h5{
								color:$white;
								margin-bottom:0px;
								margin-top:0px;
							}
							padding-right: 32px;
	
							&:after {
								content: "↪";
								position: absolute;
								right: 16px;
							}
							&:hover{
								&:after {
									content: "→";
								}
							}
						}
	
						input[type="text"]{
							padding: 14px;
							border-radius: 2px;
							border: 1px solid rgba(255, 255, 255, 0.50);
							color:white;
							background:transparent;
							font-family: Ibmplexsans, sans-serif;
							font-size: 14px;
							font-weight: 500;
							line-height: 20px;
							letter-spacing: 0.01em;

							&:focus {
								outline:none;
							}
						}
	
						.table.costs{
							.row{
								padding-top: 4px;
								padding-bottom: 4px;
								.cell{
									h5{
										color:$white;
									}
									&:last-child{
										text-align:right;
										h5{
											text-align:right;
										}
									}
								}
								&:last-child{
									padding-bottom: 0px;
								}
							}
						}
					}
				}
			}
		}

		.orders{
			.select{
				margin: 0px auto;
			}
			.row{
				.cell{
					width: 114px;
					text-align:center;
					&:first-child{
						text-align: left;
					}
					&.smaller{
						width:62px;
					}
					&:nth-child(2){
						width:92px;
						margin-left: 16px;
					}

					.productDetailWrap {
						.shop-product-name{
							padding-right:0px;
						}
						.mobilewrap{
							align-items: end;
							.headerrow{
								color: $lightblue2;
							}
							&>div:first-child{
								flex:1;
								.select{
									margin: 0px;
								}
							}
							&>div:nth-child(2){
								opacity:0.5;
								color:$lightblue2;
							}
							&>div:last-child{
								margin-left:16px;
							}
						}
					}
				}
			}
		}

		.removeicon, .mobile-shop-product-remove{
			background-image: url("./img/trashcanBlue.svg");
			background-position: center;
			background-repeat: no-repeat;
			cursor:pointer;
			width: 17px;
			height: 19px;
			margin-top: 1px;
		}
		.mobile-shop-product-remove{
			float:right;
		}
	}
}

.basketCount{
	width: 15px;
	height: 15px;
	background: #008AD1;
	border-radius: 10px;
	position: absolute;
	left: 24px;
	top: 25px;
	color: white;
	font-family: Ibmplexsans, sans-serif;
	font-size: 12px;
	font-weight: 500;
	line-height: 14px;
	text-align: center;
	display: none;
	padding-left: 1px;
	&.active{
		display:block;
	}
}


@media screen and (max-width: 767px) {
	.main-content.generic.cart, .main-content.generic.checkout{ 
		.content-section{
			.detail-tile {
				grid-row-gap: 2px;
	
				&.hasHeaderTitles{
					.detail-sidebar{
						.detail-sidebar-content{
							margin-top: 0px;
						}
					}
				}
	
				.detail-sidebar{
					grid-row-start: 2;
					grid-row-end: 3;
					.detail-sidebar-content{
						margin-top: 0px;
					}
				}
			}
			.orders{
				margin-bottom: -2px;
				.row{
					.cell{
						.productDetailWrap {
							.shop-product-name{
								padding-right:36px;
							}
						}
					}
				}
			}
		}
	}
}

.main-content.generic.checkout{ 
	.header-section{
		padding-bottom: 10px;

		.headerrow{
			margin-top:12px;
			.cell{
				flex: unset;
				margin-right:16px;
				color: $white;
				opacity: 0.5;
				&.active{
					font-weight: 500;
					opacity: 1;
				}
				&.done{
					opacity: 1;
				}
				.mobileonly{
					display:none;
				}
			}
		}
	}
	.content-section{
		.detail-tile{
			&.hasHeaderTitles{
				.detail-sidebar{
					.detail-sidebar-content{
						padding-top:8px;
					}
				}
			}
			.detail-sidebar{
				.detail-sidebar-content{
					margin-top:0;
					padding-top:0;
					.previousStep{
						display:none;
					}
				}
			}
			.table.orders{
				.row{
					flex-wrap: wrap;
					justify-content: space-between;

					&.thankyou, &.projectexamoverview{
						background:transparent;
						color:white;
						padding-left:16px;
						padding-right:16px;

						a.button-1{
							background:transparent;
							margin-top:12px;
							border: 1px solid rgba(255, 255, 255, 0.50);
							margin-bottom:60px;
							&:hover{
								background: rgba(13, 33, 57, .2);
							}
						}

					}

					&.projectexamoverview{
						.heading{
							margin-bottom:12px;
						}
						.whiteSection a{
							color:#7398C9;
						}
						.order-nav a{
							color:white;
						}
						.whiteSection h5{
							margin-bottom:16px;
						}
						.columnLayout > .text{
							margin-bottom: 32px;
						}
					}

					&.paymentblocks{
						background:none;
						.paymentblock{
							background:white;
							padding:20px 16px 20px 16px;
							border-radius:2px;
							flex:1;
							margin-right:2px;
							&:last-child{
								margin-right:0px;
							}
							.logoplaceholder{
								height:35px;
								img{
									height:100%;
								}
								margin-bottom:16px;
							}
							.bluesubtitle{
								margin-bottom:16px;
							}

							.input-radio {
								padding: 0px;
								.radio {
									p{
										color:  $blue;
										font-family: Ibmplexsans, sans-serif;
										font-size: 16px;
										font-weight: 500;
										line-height: 140%;
										letter-spacing: -0.32px;
									}
									.paymentSubdescription{
										h5{
											color: $lightblue1;
											opacity: 0.5;
										}
										margin-bottom:8px;
									}
									.ideal, .bancontact, .factuur{
										height:35px;
										width:100px;
										background-repeat: no-repeat;
										background-size: contain;
										margin-bottom:16px;
									}
									.ideal{
										background-image: url("./img/ideal.png");
									}
									.bancontact{
										background-image: url("./img/bancontact.png");
									}
									.factuur{
										background-image: none;
									}
								}
							}



						}
					}
					&.textInputsOnly{
						padding:8px 16px 8px 16px;
					}
					&.defaultPadding{
						padding:16px;
					}
					&.akkoord{
						padding:32px 26px 32px 26px;
						.bluetitle{
							width: 60%;
						}
					}
					&.addresses{
						padding:30px 20px 30px 20px;
						flex-wrap: nowrap;
						justify-content: flex-start;
						.addresswrap{
							margin-right:32px;
							&:last-child{
								margin-right:0px;
							}
							.address{
								margin-top:4px;
								opacity: 0.5;
								color: $lightblue1;
								font-family: Ibmplexsans, sans-serif;
								font-size: 14px;
								font-weight: 400;
								line-height: 140%;
								letter-spacing: -0.28px;
								span{
									font-weight: 700;
								}
							}
						}
					}

					&.defaultHideTextInputs{
						padding:16px 16px 8px 16px;
						.inputwrap{
							display:none;
						}
						&.showTextInputs{
							.inputwrap{
								display:block;
							}	
						}
					}

					.fs-checkbox_toggle-7{
						border-color:$lightblue2;
						width:45px;

						.fs-checkbox_mask-7{
							background-color: white;
						}
						.fs-checkbox_toggle-dot-7{
							background: $blue;
							transform: translate3d(0rem, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
							width:20px;
							height:20px;
						}

						&.checked{
							.fs-checkbox_mask-7{
								background-color: #d9eef8;
							}	
							.fs-checkbox_toggle-dot-7{
								transform: translate3d(21px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
							}
						}
					}
					

					.bluetitle, .bluetitle a{
						color:  $blue;
					}
					.bluetitle a{
						text-decoration: underline;
					}
					.bluesubtitle{
						color: $lightblue1;
						font-family: Ibmplexsans, sans-serif;
						font-size: 16px;
						font-weight: 500;
						line-height: 140%;
						letter-spacing: -0.32px;
						opacity: 0.5;
					}
					.currentAddress{
						color:  $lightblue1;
						opacity:0.5;
						margin-bottom:8px;
					}
					.inputwrap{
						margin:8px 0px 8px 0px;
						input[type="text"], input[type="number"], input[type="email"], input[type="password"], input[type="tel"] {
							width:100%;
							padding: 14px;
							border-radius: 2px;
							border: 1px solid rgba(115, 152, 201, 0.30);
							color: $lightblue1;
							background:transparent;
							font-family: Ibmplexsans, sans-serif;
							font-size: 14px;
							font-weight: 500;
							line-height: 20px;
							letter-spacing: 0.01em;
							&:focus {
								outline:none;
							}
						}
						&.part50{
							width:calc(50% - 4px);
						}
						&.part25{
							width:calc(25% - 6px);
						}
						&.part33{
							width: calc(33.33% - 5.33px);
						}
					}
				}
				.previousStep{
					padding:14px 14px 50px 0px;
					a {
						color: $white;
					}
				}
			}
		}
	}
}

.cartlogin{
	padding:10px;
	color:$mainColor;

	.input-checkbox p{
		display: inline-block;
	}
	.inputwrap{
		max-width:250px;
	}
	a.button-1{
		margin-top:15px;
	}

}

.main-content.webshopproduct .productcontent-content .darkbluesection .smallnote.firstloginmessage{
	margin-top:15px;
	color:white;
  	margin-bottom: 25px;
	a{color:#C3E4F9;}
}

.bluecontentwrapper.bluecontentwrapperEnabled{
	background-color: #008AD1;
  	position: relative;
	.detail-content {
		padding-top:40px;
	}
}

body.blueFooter .footer{
	background-color: #008AD1;
}

.breadcrumbsOuter .desktop-only{
	display:block;
}
.breadcrumbsOuter .mobile-only{
	display:none;
}

.examcontent-block-left .examBlock .item {
	border-top: 1px solid #b2dbf1;
	padding: 14px 0;
	span {
		width: 52%;
		display: inline-block;
		&:first-child {
			width: 46%;
		}
	}
	&:last-child {
		border-bottom: 1px solid #b2dbf1;
	}
}

.cookieBar, .accessibilityBar{
	background:black;
	height:60px;
	display: table;
	width: 100%;
	position: relative;
  	z-index: 100;
	
	.cookieBarBtn, .accessibilityBarBtn{
		display: table-cell;
		text-align: right;
		vertical-align: middle;
		padding: 2px 15px 2px 15px;
		.front-btn{
			height:38px;
			padding: 5px 20px 0;
		}	  
	}
	
	.cookieBarTxt, .accessibilityBarTxt{
		color:white;
		line-height:15px;
		display: table-cell;
		text-align: center;
		vertical-align: middle;
		padding: 2px 15px 2px 15px;
	  
		.overlayClose{
			background-image: url(img/close.png);
			background-repeat: no-repeat;
			background-position: top center;
			width:14px;
			height:14px;
			float: right;
			margin-top: -10px;
			margin-right: -15px;
			cursor:pointer;
		}
	}
	.button-1{
		padding: 10px 25px;
	}
}

.accessibilityBar{
	height: 74px;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
	padding-right: 300px;
	.accessibilityBarTxt p{
		margin-bottom:0px;
	}
}

.nav.w-nav.header.cookiebarvisible{
	padding-top:50px;
	.nav-menu-component, .profile-menu-component, .profileloggedin-menu-component, .cart-menu-component {
		padding-top: 70px;
	}
}

.currentFile{
	display: flex;
	.currentFileContent{
		display: flex;
		flex-direction: column;
		img{
			margin-bottom:10px;
		}
	}
	.icon.icon-remove{
		display:block;
		width:24px;
		height:24px;
		margin-left: 10px;
		background-image: url("./img/closeiconWhite.svg");
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}
}

.presentExemplaarBlocksWrapper{
	margin-top:20px;
	margin-bottom:20px;
}

.presentExemplaarBlocksWrapper .input-checkbox p {
	display: inline;
}

.main-content.generic.cart.aanvraagPresentExemplaarCart .header-section{
	padding-top:80px;
	padding-left:0px;
}

body.aanvraagPresentExemplaarOverview, body.aanvraagPresentExemplaarBlocks{
	.breadcrumb{
		margin-left:0px;
	}
}

.previousStep{
	padding:0px 18px 0px 0px;
	align-self: center;
	display:flex;
	a {
		color: $white;
	}
}

.aanvraagPresentExemplaarHeader{
	h2{
		color:white;
	}
	a.backLink{
		color:white;
	}
	.mt-1{
		color:white;
	}
}

.form-section {
	.form-section-title{
		margin-top:30px;
	}
	&:first-child{
		.form-section-title{
			margin-top:0px;
		}	
	}
}

.obiLaunchButton{
	width:195px;
	height:56px;
	border-radius:100px;
	background-color:#58C175;
	color:#0D2139;
	font-family: Ibmplexsans, sans-serif;
	position:fixed;
	right:48px;
	bottom:48px;
	cursor:pointer;
	
	padding-left:25px;
	z-index: 9999;
	background-image: url("./img/chat.svg");
	background-position: center right 20px;
	background-repeat: no-repeat;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;

	.openwrapper, .closedwrapper{
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
	}

	&.open{
		background-image: url("./img/cross.svg");
	}

	.openwrapper{
		display:flex;
	}
	&.open .openwrapper{
		display:none;
	}
	.closedwrapper{
		display:none;
	}
	&.open .closedwrapper{
		display:flex;
	}

	.title{
		font-weight:500;
		font-size:14px;
		line-height:16px;
		letter-spacing: -1%;
	}
	.subtitle{
		font-weight:400;
		font-size:14px;
		line-height:16px;
		letter-spacing: -2%;
	}
}

.admin a.btn.btn-icon.btn-danger .icon.icon-remove{
	line-height: 33px;
}

@media screen and (max-width: 767px) {

	.obiLaunchButton{
		right:16px;
		bottom:16px;
	}

	.textrightwithbigpic .w-layout-grid .gridnode2{
		min-height:auto;
	}
	.nav.w-nav.header.cookiebarvisible{
		padding-top:104px;
		.nav-menu-component, .profile-menu-component, .profileloggedin-menu-component, .cart-menu-component {
			padding-top: 120px;
		}
	}
	.accessibilityBar {
		padding-right: 0px;
		padding-bottom: 65px;
		padding-top: 10px;
	}
	.breadcrumbsOuter .desktop-only{
		display:none;
	}
	.breadcrumbsOuter .mobile-only{
		display:block;
	}
	.main-content.generic.checkout{ 
		.header-section{	
			.headerrow{
				.cell{
					display: none;
					&.active{
						display:block;
					}
					.mobileonly{
						display:inline;
					}
				}
			}
		}
		.content-section{
			.detail-tile{
				.detail-sidebar{
					.detail-sidebar-content{
						margin-top:0;

						.flexcolumnwrap{
							display:flex;
							flex-direction: row;
						}

						.previousStep{
							padding:0px 18px 0px 0px;
							align-self: center;
							display:flex;
							a {
								color: $white;
							}
						}
						.detail-sidebar-item.linkarrow{
							flex:1;
						}
					}
				}
				.table.orders{
					margin-bottom: -2px;
					.row{
						flex-wrap: wrap;
						padding:8px 16px 8px 16px;
						justify-content: space-between;
						
						&.thankyou, &.projectexamoverview{
							padding: 0px;

							a.button-1{
								margin-top:20px;
							}
						}

						&.paymentblocks{
							padding: 0px;
							.paymentblock{
								flex:unset;
								width:100%;
								margin-right:0px;
								margin-bottom:2px;
								&:last-child{
									margin-bottom:0px;
								}
							}
						}

						&.addresses{
							flex-wrap: wrap;
							.addresswrap{
								width:100%;
								margin-right:0px;
								margin-bottom:16px;
								&:last-child{
									margin-bottom:0px;
								}
							}
						}
						&.akkoord{
							padding-bottom: 26px;
							.bluetitle{
								width:100%;
								margin-bottom:8px;
							}
						}


						.bluetitle{
							width:100%;
						}

						.fs-checkbox_toggle-7{
							width:61px;
							margin: 8px 0px 16px 0px;

							.fs-checkbox_toggle-dot-7{
								width:28px;
								height:28px;
							}
							&.checked{
								.fs-checkbox_toggle-dot-7{
									transform: translate3d(29px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
								}
							}
						}

						.inputwrap{
							margin:8px 0px 8px 0px;
							&.part50, &.part25, &.part33{
								width:auto;
							}
							&.mobile100{
								width:100%;
							}
							&.mobile50{
								width:calc(50% - 4px);
							}
						}
					}
					.previousStep{
						display:none;
					}
				}
			}
		}
	}

	.main-content.generic.myaccount .content-section .nawdata .row.profileblock .gridwrapper .form-section .form-inputs {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}
	.main-content.generic.myaccount .content-section .nawdata {
		min-width: unset;
	}
	.main-content.generic.myaccount .content-section .nawdata .row.headerrow{
		align-items: flex-end;
	}
	.main-content.generic.myaccount .content-section .nawdata .row.headerrow .cell:last-child{
		padding-right:0px;
	}

}

a.reservedTimerBar, .reservedTimerBar{
	background:#FFB800;
	padding: 4px 6px 4px 6px;
	color:$black;
	display:none;
	&.active{
		display:block;
	}
	
	.reservedTimerBarTxt{
		text-align: center;
	}
}

.page-wrapper.activeTimerBar{
	.nav{
		top:28px;
		@media screen and (max-width: 767px) {
			top:48px;
		}
	}
}

.timericon{
	display:inline-block;
	margin-right:8px;
	height: 19px;
	background-image: url("./img/timer-icon.svg");
	background-position: center;
	background-repeat: no-repeat;
	width: 8px;
}


input[type="text"], input[type="number"], input[type="email"], input[type="password"], input[type="tel"], textarea{
	padding: 14px;
	border-radius: 2px;
	border: 1px solid rgba(115, 152, 201, 0.3);
	background: transparent;
	font-family: Ibmplexsans, sans-serif;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.01em;
	&:focus{
		outline: none;
	}
}

.detail-content-block .threecolumnBlocks .block {
	h5.linksubheaderlabel{
		color:$lightblue1;
		display: block;
		font-size: 18px;
		font-weight: 500;
		line-height: 25px;
		letter-spacing: -0.02em;
		text-align: left;
	}
	.mt-1.bodyText{
		color: #7398C9;
	}
}

.menu-content{
	.input-checkbox p {display:inline-block;}

	a.button-1 {
		border-color: rgba(115, 152, 201, 0.3);
		color: #008ad1;
		margin-top: 16px;
		background-color:white;
		-webkit-backdrop-filter: unset;
  		backdrop-filter: unset;
		&:hover{
			background-color:rgba(0,0,0,0.1);
		}
	}

	.fieldsetwrapper{
		margin:16px 0px 16px 0px;
	}

	fieldset {
		padding: 8px 14px 14px 14px;
		margin-top: -6.5px;
		display: inline-block;

		&.roundedfieldset {
			color: #008ad1;
			border-color: rgba(115, 152, 201, 0.3);
			border-radius: 100vw !important;
			padding: 8px 16px 12px 16px;
			text-decoration: none !important;
			font-size: 10px !important;
			font-weight: 500 !important;
			line-height: 14px !important;
		}

		input {
			border: none;
			margin: 0;
			padding: 0px 5px 0px 5px;
			height: 20px;
			background: none;
			width: 175px;
		}
	}

}

.focusimage{
	background-repeat:no-repeat;
	background-size: cover;
}

.error{
	color: red;
}

.menu-content .error{
	margin-bottom: 16px;
}

a.profileloggedin.nav-item-menu{
	color:white;
	font-family: Ibmplexsans, sans-serif;
	font-size:12px;
	line-height:14px;
	font-weight:500;
}

.profileloggedin-menu-component{
	.nav-menu-block {
		.menu-content {
			.menulink {
				padding-top:4px;
				padding-bottom:4px;
				&:first-child{
					padding-top:0;
				}
				&:last-child{
					padding-bottom:0;
				}
				a{
					font-size:24px;
					font-family: Ibmplexsans, sans-serif;
					line-height:34px;
					font-weight:400;
				}
			}
		}
	}
}

.svhTooltip {
	display:none;
}

.input-errors.hidden{
	display:none;
}


.main-content.generic.checkout .content-section .nawinputs .select{
	width:100%;
	color: #7398C9;
	&:after{
		top: 12px;
		right: 12px;
	}
	select {
		border-radius: 2px;
		padding: 14px;
	}
}

.main-content.generic{
	.login{
		.input-checkbox{
			label.checkbox{
				display: flex;
				input[type="checkbox"]{
					margin-right:5px;
				}
			}
		}
		.front-btn.button-2{
			margin-top:15px;
			margin-bottom:10px;
		}
	}
}

.registers .introsection{
	margin-bottom:30px;
	.link{margin-top:20px;display:inline-block;}
}

body.register, .registers {
	.detail-content-block{
		padding: 26px 36px 26px 26px;
	}
	.detail-sidebar-content{
		padding-top:0px;
	}

	.detail-tile .detail-sidebar .detail-sidebar-content .detail-sidebar-item.linkarrow{
		padding-right: 32px;	

		&:after {
			content: "↪";
			position: absolute;
			right: 16px;
		}

		&:hover{
			&:after {
				content: "→";
			}
		}
	}
	
	.detail-sidebar-content h5 {
		margin-bottom: 0px;
		margin-top: 0px;
	  }

	.form-inputs {
		.form-input-result{
			display:flex;
			margin-bottom:16px;
		}
		.title{
			flex: 0 0 40%;
		}
		.value {
			flex:1;
			align-self: center;

			.input-type-radio, .input-type-checkbox{
				display:flex;
				label{
					margin:0px 15px 0px 4px;
				}
			}
			.input-type-checkbox{
				align-items: start;
				input[type="checkbox"]{
					margin-top: 4px;
				}
				label{
					margin-left:6px;
				}
			}
		}
	}
}

.projectexamen {
	.productDetail {
		.form-input-result{
			display:flex;
			margin-bottom:16px;
			&.input-saveaddress{
				.value{
					vertical-align: middle;
				}
			}
			&.addressbook{
				.remove-address-btn{
					cursor:pointer;
					margin-bottom:20px;
				}
				.remove-address-btn.hidden{
					display:none;
				}
			}
			
		}
		.title{
			flex: 0 0 40%;
		}
		.value {
			flex:1;
			align-self: center;

			.input-type-radio, .input-type-checkbox{
				display:flex;
				label{
					margin:0px 15px 0px 4px;
				}
			}
			.input-type-checkbox{
				align-items: start;
				input[type="checkbox"]{
					margin-top: 4px;
				}
				label{
					margin-left:6px;
				}
			}
		}
	}
}


.detail-content-block p, .detail-content-block li, .whiteSection p, .whiteContentBlock p, .stack-card.textrightwithbigpic .numberedblock p{
	a, a:link, a:visited {
		color:$darkblue;
		text-decoration: underline;
	}
	a:visited {
		color:$blue;
	}
}

.detail-content-block .maxHeightWithFade .detailpagesmallimage{
	display:none;
}

.detail-content-block.opened .maxHeightWithFade {
	position: unset;
}

.detail-content-block.opened .maxHeightWithFade .detailpagesmallimage{
	display:block;
}

