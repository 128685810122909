// TRANSFORM
@mixin transform($transform) {
	-webkit-transform: $transform;
	-ms-transform: $transform;
	transform: $transform;
}

// TRANSFORM ORIGIN
@mixin transformOrigin($origin) {
	-webkit-transform-origin: $origin;
	-ms-transform-origin: $origin;
	transform-origin: $origin;
}

// ROUNDED RADIUS
@mixin radius($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	-ms-border-radius: $radius;
	border-radius: $radius;
}

// TOP RADIUS
@mixin radiusT($radius) {
	-webkit-border-top-left-radius: $radius;
	-webkit-border-top-right-radius: $radius;
	-moz-border-radius-topleft: $radius;
	-moz-border-radius-topright: $radius;
	border-top-left-radius: $radius;
	border-top-right-radius: $radius;
}

// BOTTOM RADIUS
@mixin radiusB($radius) {
	-webkit-border-bottom-right-radius: $radius;
	-webkit-border-bottom-left-radius: $radius;
	-moz-border-radius-bottomright: $radius;
	-moz-border-radius-bottomleft: $radius;
	border-bottom-right-radius: $radius;
	border-bottom-left-radius: $radius;
}

// LEFT RADIUS
@mixin radiusL($radius) {
	-webkit-border-top-left-radius: $radius;
	-webkit-border-bottom-left-radius: $radius;
	-moz-border-radius-topleft: $radius;
	-moz-border-radius-bottomleft: $radius;
	border-top-left-radius: $radius;
	border-bottom-left-radius: $radius;
}

// RIGHT RADIUS
@mixin radiusR($radius) {
	-webkit-border-top-right-radius: $radius;
	-webkit-border-bottom-right-radius: $radius;
	-moz-border-radius-topright: $radius;
	-moz-border-radius-bottomright: $radius;
	border-top-right-radius: $radius;
	border-bottom-right-radius: $radius;
}


// TOP LEFT RADIUS
@mixin radius-topleft($radius) {
	-webkit-border-top-left-radius: $radius;
	-moz-border-radius-topleft: $radius;
	border-top-left-radius: $radius;
}

// TOP RIGHT RADIUS
@mixin radius-topright($radius) {
	-webkit-border-top-right-radius: $radius;
	-moz-border-radius-topright: $radius;
	border-top-right-radius: $radius;
}

// BOTTOM LEFT RADIUS
@mixin radius-bottomleft($radius) {
	-webkit-border-bottom-left-radius: $radius;
	-moz-border-radius-bottomleft: $radius;
	border-bottom-left-radius: $radius;
}

// BOTTOM RIGHT RADIUS
@mixin radius-bottomright($radius) {
	-webkit-border-bottom-right-radius: $radius;
	-moz-border-radius-bottomright: $radius;
	border-bottom-right-radius: $radius;
}

// SHADOW
@mixin shadow($shadow) {
	-webkit-box-shadow: $shadow;
	-moz-box-shadow: $shadow;
	box-shadow: $shadow;
}

// TRANSITION
@mixin transition($transition) {
	-webkit-transition: $transition;
	-moz-transition: $transition;
	-ms-transition: $transition;
	transition: $transition;
}

// TRANSITION-DELAY
@mixin transitionD($transition) {
	-webkit-transition-delay: $transition;
	-moz-transition-delay: $transition;
	transition-delay: $transition;
}

// BOX-SIZING
@mixin boxS($sizing) {
	-webkit-box-sizing: $sizing;
	-moz-box-sizing: $sizing;
	box-sizing: $sizing;
}

// BACKGROUND-SIZE
@mixin backgroundS($size) {
	-webkit-background-size: $size;
	-moz-background-size: $size;
	-o-background-size: $size;
	background-size: $size;
}

// TEXT SHADOW
@mixin tshadow($shadow) {
	text-shadow: $shadow;
}

// KEYFRAMES
@mixin keyframes($animation-name) {
	@-webkit-keyframes #{$animation-name} {
		@content;
	}
	@-moz-keyframes #{$animation-name} {
		@content;
	}
	@-ms-keyframes #{$animation-name} {
		@content;
	}
	@-o-keyframes #{$animation-name} {
		@content;
	}
	@keyframes #{$animation-name} {
		@content;
	}
}

// ANIMATION
@mixin animation($str) {
	-webkit-animation: #{$str};
	-moz-animation: #{$str};
	-ms-animation: #{$str};
	-o-animation: #{$str};
	animation: #{$str};
}

// FONT SIZE
@function calculateRem($size) {
  $remSize: calc($size / 16px);
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

// LETTER SPACING
@function calculateSpacing($size) {
	$pxSize: calc($size / 1000);
	@return $pxSize + 0em; // We need to know what unit we need to return, with +0em the value of pxSize will be in em as return value
}

@mixin letter-space($size) {
	letter-spacing: calculateSpacing($size);
}

// APPEARANCE
@mixin appearance($val) {
	-webkit-appearance: $val;
  -moz-appearance: $val;
  appearance: $val;
}

// COLOR
@mixin rgba-color($hexcolor, $opacity) {
  color: $hexcolor;
  color: rgba($hexcolor, $opacity);
}

@mixin rgba-background($hexcolor, $opacity) {
  background-color: $hexcolor;
  background-color: rgba($hexcolor, $opacity);
}

@mixin rgba-border($hexcolor, $opacity) {
  border-color: $hexcolor;
  border-color: rgba($hexcolor, $opacity);
}

// GRADIENT
@mixin gradient($from, $startColor, $to, $endColor) {
	background: $startColor;
	background: -moz-linear-gradient($from, $startColor 0%, $endColor 100%);
	background: -webkit-linear-gradient($from, $startColor 0%, $endColor 100%);
	background: linear-gradient(to $to, $startColor 0%, $endColor 100%);
}