.homeContainer {
	&.errorPage {
		padding-top: 100px;
		background-color: #0073BA;
		
		.inner-content {
			display: block;
			margin: 0 auto;
			max-width: 670px;
			width: 100%;
			
			text-align: center;
			
			h1 {
				font-weight: normal;
				@include font-size(64px);
				font-family: $secondFont;
				line-height: 70px;
				color: $darkColor;
				margin-bottom: 30px;
			}
			
			.btn {
				@include font-size(16px);
				@include radius(2px);
				color: $darkColor;
				font-family: $mainbFont;
				padding: 9px 22px 13px;
			}
		}
		
		.backgroundImage {
			margin-top: -115px;
			
			.imageContainer {
				background-position: center left 25%;
			}
			
			.gradientOverlay {
				position: absolute;
				top: -4px;
				left: 0;
				right: 0;
				width: 100%;
				height: 97px;
				
				background: -moz-linear-gradient(top, rgba(0,115,186,1) 0%, rgba(0,112,185,0) 99%, rgba(0,112,185,0) 100%);
				background: -webkit-linear-gradient(top, rgba(0,115,186,1) 0%,rgba(0,112,185,0) 99%,rgba(0,112,185,0) 100%);
				background: linear-gradient(to bottom, rgba(0,115,186,1) 0%,rgba(0,112,185,0) 99%,rgba(0,112,185,0) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0073ba', endColorstr='#000070b9',GradientType=0 );
				
				&.ltr {
					background: -moz-linear-gradient(left, rgba(0,115,186,1) 0%, rgba(0,112,185,0) 99%, rgba(0,112,185,0) 100%);
					background: -webkit-linear-gradient(left, rgba(0,115,186,1) 0%,rgba(0,112,185,0) 99%,rgba(0,112,185,0) 100%);
					background: linear-gradient(to right, rgba(0,115,186,1) 0%,rgba(0,112,185,0) 99%,rgba(0,112,185,0) 100%);
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0073ba', endColorstr='#000070b9',GradientType=1 );
					
					width: 97px;
					height: 100%;
				}
			}
		}
	}
}